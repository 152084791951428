<template>
	<div style="display: contents;">
		<v-checkbox
			style="display: inline-block"
			v-bind="$attrs"
			v-on="$listeners"
			label="ОТКАЖИ"
			hide-details
			v-model="checkbox"
			@change="onDeclineChange($event)"
		/>
		<div v-if="checkbox">{{ reason }}</div>
		<my-dialog ref="dialog" v-model="dialog" width="500" title="Причина" hide-close persistent>
			<v-container fluid>
				<v-form ref="form">
					<v-combobox ref="reason" dense outlined hide-details="auto"
						:items="reasonsList"
						v-model="reasonInput"
						:rules="rules"
					/>
				</v-form>
			</v-container>
			<template #actions>
				<v-btn text @click="doCancel">Затвори</v-btn>
				<v-spacer/>
				<v-btn color="primary" @click="doConfirm">Потвърди</v-btn>
			</template>
		</my-dialog>
	</div>
</template>

<script>
import MyDialog from "@/ittijs/components/MyDialog.vue";

const reasonsList = [
	'не е на адреса',
	'няма складова наличност',
	'няма физ. възможност за монтаж',
	'няма фин. възможност',
	'отлага монтаж',
];

export default {
	components: {MyDialog},
	props: {
		value: {},
		reason: {},
	},
	data(){
		return {
			dialog: false,
			checkbox: this.value === true || this.value === '1',
			reasonsList,
			reasonInput: this.reason,
			rules: [
				value => value != '' ? true : 'Изберете причина'
			],
		}
	},
	watch: {
		value(val){
			this.checkbox = val === true || val === '1';
		},
		reason(val){
			this.reasonInput = val;
		},
	},
	methods: {
		onDeclineChange(val){
			if (val) {
				this.$refs.form?.resetValidation();
				this.dialog = true;
			}
			else {
				this.$emit('input', false);
				this.$emit('update:reason', '');
				this.$emit('change');
			}
		},
		doCancel(){
			this.dialog = false;
			this.checkbox = false;
			this.reasonInput = '';
			this.$refs.form.resetValidation();
		},
		doConfirm(){
			// let the combobox update reasonInput
			this.$refs.reason.blur();
			this.$nextTick(() => {
				if (!this.$refs.form.validate()) return;
				this.dialog = false;
				this.$emit('input', true);
				this.$emit('update:reason', this.reasonInput);
				this.$emit('change');
			});
		},
	},
}
</script>