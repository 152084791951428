<template>
	<v-container>
		<v-sheet outlined rounded>
			<v-expansion-panels flat>
				<v-expansion-panel>
					<v-expansion-panel-header
						class="pa-2"
						@click="open = !open"
						:expand-icon="open ? 'mdi-chevron-up' : 'mdi-chevron-down'"
					>
						<span>
							<v-icon>mdi-filter</v-icon>
							Търсене
						</span>
					</v-expansion-panel-header>
					<v-expand-transition>
						<div v-show="open">
							<v-divider></v-divider>
							<slot v-bind="filter"></slot>

							<v-container>

								<v-row dense v-for="(rule, index) in filter.rules" :key="index">
									<v-col cols="3"><filter-field v-model="rule.field" /></v-col>
									<v-col cols="3">
										<div class="d-flex align-center">
											<v-switch v-model="rule.not" label="NOT" dense hide-details class="mt-0 mr-2" />
											<filter-op v-model="rule.op" :rule="rule" />
										</div>
									</v-col>
									<v-col cols="5"><filter-value v-model="rule.value" :rule="rule" /></v-col>
									<v-col cols="1">
										<v-btn icon @click="doRemoveRule(index)">
											<v-icon>mdi-filter-remove</v-icon>
										</v-btn>
									</v-col>
								</v-row>

								<v-row dense>
									<v-col cols="12">
										<v-btn @click="filter.rules.push({field:null, op:null, value:null})">
											<v-icon left>mdi-filter-plus</v-icon>
											Добави условие
										</v-btn>
									</v-col>
								</v-row>

							</v-container>
							<v-divider></v-divider>
							<v-toolbar flat dense color="transparent">
								<v-spacer></v-spacer>
								<v-btn @click="reset" text class="ml-2">
									<v-icon left>mdi-filter-off</v-icon>
									Изчисти
								</v-btn>
								<v-btn @click="search" color="primary" class="ml-2">
									<v-icon left>mdi-magnify</v-icon>
									Търсене
								</v-btn>
							</v-toolbar>
						</div>
					</v-expand-transition>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-sheet>
	</v-container>
</template>

<script>

import SearchMixin from './SearchMixin';
import FilterField from "@/ittijs/Filter/Field";
import FilterOp from "@/ittijs/Filter/Op";
import FilterValue from "@/ittijs/Filter/Value";
import Vue from "vue";

export default {
	components: {FilterValue, FilterOp, FilterField},
	mixins: [SearchMixin],
	methods: {
		doRemoveRule(i){
			Vue.delete(this.filter.rules, i);
		},
	},
};
</script>