<template>
	<my-dialog v-model="isOpen" v-bind="propsDialog" :title="title" :max-width="dialogMaxWidth">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs }" />
		</template>
		<component
			:is="editComponent"
			hide-title
			v-if="isOpen"
			v-bind="$attrs"
			v-on="$listeners"
			@close="isOpen = false"
			@saveclose="isOpen = false; $emit('reload')"
			@delete="isOpen = false; $emit('reload')"
		>
			<template v-for="slot of editSlots" v-slot:[slot]="scope">
				<slot :name="'edit.'+slot" v-bind="scope"/>
			</template>
		</component>
	</my-dialog>
</template>

<script>
import EditColumn from "@/ittijs/EditColumn";
import MyDialog from "@/ittijs/components/MyDialog.vue";

export default {
	components: {
		MyDialog,
	},
	props: {
		title: {
			type: String,
			default: 'Редакция',
		},
		propsDialog: Object,
		dialogMaxWidth: Number,
		editComponent: {
			type: [String, Object],
			default: () => EditColumn,
		}
	},
	data(){
		return {
			isOpen: false,
		}
	},
	computed: {
		// slots for the edit control, prefixed with "edit."
		editSlots(){
			const prefix = 'edit.';
			return Object.keys(this.$scopedSlots)
				.filter(slot => slot.startsWith(prefix))
				.map(slot => slot.substring(prefix.length));
		},
	},
	methods: {
		open(){
			this.isOpen = true;
		},
	},
}
</script>