<script>
import {calcDiscountPercent, floatSum} from "@/ittijs/utils";

export default {
	props: {
		services: Array,
		value: Object,
	},
	data(){
		return {
			innerValue: {},
		}
	},
	watch: {
		value: {
			immediate: true,
			handler(v){
				this.innerValue = v;
			},
		},
		innerValue: {
			deep: true,
			handler(v){
				this.$emit('input', v);
			},
		}
	},
	computed: {
		service(){
			return this.services.find(s => s.id == this.innerValue.service_id) || null;
		},
		isDeclined(){
			switch (this.innerValue?.is_declined) {
				case true:
				case 1:
				case '1':
					return true;
			}
			return false
		},
	},
	methods: {
		getDefaultPrice(){
			if (!this.service || this.isDeclined) return null;
			if (this.value.id && this.value.original_service_id && this.value.original_service_id == this.value.service_id) {
				return floatSum(this.value.price_service_original, this.value.price_product_original).toFixed(2);
			}
			return floatSum(this.service.price_service, this.service.price_product).toFixed(2);
		},
		getDiscount(){
			if (!this.service || this.isDeclined) return null;
			return calcDiscountPercent(this.getDefaultPrice(), this.innerValue.price, 2);
		},
		onServiceChange(){
			this.$emit('input', {
				...(this.innerValue || {}),
				price: this.getDefaultPrice(),
			});
		},
	},
	/**
	 * Renderless component which only returns the scoped slot "default"
	 */
	render(){
		return this.$scopedSlots.default({
			value: this.innerValue,
			defaultPrice: this.getDefaultPrice(),
			discount: this.getDiscount(),
			service: this.service,
			onServiceChange: this.onServiceChange,
		});
	},
}
</script>