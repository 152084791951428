<template>
	<div class="my-container"
		:style="{
			width: `${width??250}px`,
		}"
		@mouseenter="onMouseEnter"
		@mousemove="onMouseMove"
		@mouseleave="onMouseLeave"
		@click="onClick"
	>

		<div
			v-for="(item, index) in intervals"
			:key="'interval'+index"
			:class="{
				'my-interval': true,
				disabled: item.last,
			}"
			:style="{
				height: `${ intervalHeight }px`,
			}"
		>
			<div class="caption">{{ item.time }}</div>
		</div>

		<v-sheet v-for="(item, index) in eventsDecorated"
			:key="'event'+index"
			elevation="1"
			class="my-event"
			rounded
			:color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'"
			:style="item.style"
			@click="$emit('click:event', item)"
		>
			<slot name="event" v-bind="{start: item.start, end: item.end, event: item}">
				{{ item.start.toString() }} - {{ item.end.toString() }}
			</slot>
		</v-sheet>

		<v-sheet
			v-if="valueMinutes !== null"
			elevation="1"
			class="my-placeholder"
			rounded
			v-bind="valueAttrs"
			:color="$vuetify.theme.dark ? 'success darken-2' : 'success lighten-2'"
		>
			<v-btn fab x-small absolute top right color="error"
				v-if="valueMinutes !== null"
				@click.stop="onClickClear"
			>
				<v-icon>mdi-close</v-icon>
			</v-btn>
			{{ valueStart?.toString() }} - {{ valueEnd?.toString() }}
		</v-sheet>

		<v-sheet
			v-if="showSelection"
			elevation="2"
			class="my-placeholder"
			style="pointer-events: none"
			rounded
			v-bind="placeholderAttrs"
		>
			{{ selectionStart?.toString() }} - {{ selectionEnd?.toString() }}
		</v-sheet>

	</div>
</template>

<script>
import {TimeOfDay} from "@/ittijs/utils";

const makeTimeOfDay = function(time) {
	switch (typeof time) {
		case "string":
			return TimeOfDay.fromString(time);
		case "number":
			return new TimeOfDay(time);
		case "object":
			if (time instanceof TimeOfDay) return time;
			break;
	}
	return null;
}

export default {
	props: {
		events: {
			type: Array,
			default: () => [],
		},
		start: String,
		end: String,
		intervalMinutes: {
			type: Number,
			default: 30,
		},
		intervalHeight: {
			type: Number,
			default: 48,
		},
		selectionMinutes: Number,
		precisionMinutes: {
			type: Number,
			default: 5,
		},
		width: Number,
		value: String,
		noSelection: Boolean,
	},
	data () {
		return {
			showSelection: false,
			pointerMinutes: null,
			valueMinutes: null,
		};
	},
	computed: {
		timeStart(){
			return this.start ? TimeOfDay.fromString(this.start) : null;
		},
		timeEnd(){
			return this.end ? TimeOfDay.fromString(this.end) : null;
		},
		eventsDecorated(){
			return this.events.map(event => ({
				...event,
				start: makeTimeOfDay(event.start),
				end: makeTimeOfDay(event.end),
				style: {
					top: `${ this.minutesToPixels( makeTimeOfDay(event.start)?.minutes() - this.timeStart?.minutes()) }px`,
					height: `${ this.minutesToPixels(this.duration(event.start, event.end) ?? 0) }px`,
					cursor: this.$listeners['click:event'] ? 'pointer' : null,
				},
			}));
		},
		/**
		 * Array of time positions for all intervals
		 */
		intervals(){
			const interval = parseInt(this.intervalMinutes);
			if (isNaN(interval) || interval <= 0) return [];
			if (!(this.timeStart && this.timeEnd)) return [];
			const result = [];
			let pointer = this.timeStart;
			while (pointer.minutes() <= this.timeEnd.minutes()) {
				result.push({
					minutes: pointer.minutes(),
					time: pointer.toString(),
					offset: pointer.minutes() - this.timeStart.minutes(),
					last: pointer.minutes() === this.timeEnd.minutes(),
				});
				pointer = pointer.addMinutes(interval);
			}
			return result;
		},
		// position of pointer block (top) in minutes after start
		selectionStartMinutes() {
			if (this.pointerMinutes===null) return null;
			let start = this.pointerMinutes - this.selectionMinutes / 2;
			start = Math.round(start / this.precisionMinutes) * this.precisionMinutes;
			if (this.timeStart.minutes() + start + this.selectionMinutes > this.timeEnd.minutes()) {
				start = this.timeEnd.minutes() - this.timeStart.minutes() - this.selectionMinutes;
			}
			if (start < 0) {
				start = 0;
			}
			return start;
		},
		// time equivalent for selectionStartMinutes (start of pointer block)
		selectionStart() {
			return this.selectionStartMinutes!==null && this.timeStart!==null
				? this.timeStart.addMinutes(this.selectionStartMinutes)
				: null;
		},
		// time equivalent for selectionStartMinutes + duration (end of pointer block)
		selectionEnd() {
			return this.selectionStartMinutes!==null && this.timeStart!==null && this.selectionMinutes!==null
				? this.timeStart.addMinutes(this.selectionMinutes + this.selectionStartMinutes)
				: null;
		},
		valueStart() {
			return this.valueMinutes!==null && this.timeStart!==null
				? this.timeStart.addMinutes(this.valueMinutes)
				: null;
		},
		valueEnd() {
			return this.valueMinutes!==null && this.timeStart!==null && this.selectionMinutes!==null
				? this.timeStart.addMinutes(this.selectionMinutes + this.valueMinutes)
				: null;
		},
		valueAttrs() {
			return {
				style: {
					height: `${ this.minutesToPixels(this.selectionMinutes) }px`,
					top: `${ this.minutesToPixels(this.valueMinutes) }px`,
				},
			}
		},
		placeholderAttrs() {
			return {
				style: {
					height: `${ this.minutesToPixels(this.selectionMinutes) }px`,
					top: `${ this.minutesToPixels(this.selectionStartMinutes) }px`,
				},
				color: this.targetCollision
					? (this.$vuetify.theme.dark ? 'red darken-4' : 'red lighten-3')
					: (this.$vuetify.theme.dark ? 'light-blue darken-3' : 'light-blue lighten-2')
				,
			}
		},
		targetCollision() {
			if (this.selectionStartMinutes===null) return false;
			if (this.selectionEnd && this.timeEnd && this.selectionEnd.minutes() > this.timeEnd.minutes()) {
				// duration is too big, end time is outside the valid region
				return true;
			}
			return this.events.some(event => {
				const start = makeTimeOfDay(event.start);
				const end = makeTimeOfDay(event.end);
				if (end.minutes() <= this.selectionStartMinutes + this.timeStart.minutes()) return false;
				if (start.minutes() >= this.selectionStartMinutes + this.selectionMinutes + this.timeStart.minutes()) return false;
				return true;
			});
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(val){
				if (typeof val === "string" && val !== '') {
					val = TimeOfDay.fromString(val.substring(0, 5));
					if (val) {
						if (this.timeStart) val = val.addMinutes(0 - this.timeStart.minutes());
						this.valueMinutes = val.minutes();
						return;
					}
				}
				this.valueMinutes = null;
			},
		},
	},
	methods: {
		clear() {
			this.valueMinutes = null;
		},
		onClickClear(){
			this.clear();
			this.$emit('input', null);
		},
		duration(start, end) {
			start = makeTimeOfDay(start);
			end = makeTimeOfDay(end);
			if (!(start && end)) return null;
			return end.minutes() - start.minutes();
		},
		minutesToPixels(minutes) {
			return minutes * this.intervalHeight / this.intervalMinutes;
		},
		pixelsToMinutes(pixels) {
			return Math.floor(
				pixels * this.intervalMinutes / this.intervalHeight
			);
		},
		onMouseEnter(e) {
			if (this.noSelection) return;
			this.showSelection = true;
			this.pointerMinutes = this.pixelsToMinutes(e.clientY - this.$el.getBoundingClientRect().top);
		},
		onMouseMove(e) {
			if (this.noSelection) return;
			this.showSelection = true;
			this.pointerMinutes = this.pixelsToMinutes(e.clientY - this.$el.getBoundingClientRect().top);
		},
		onMouseLeave() {
			if (this.noSelection) return;
			this.showSelection = false;
			this.pointerMinutes = null;
		},
		onClick() {
			if (this.noSelection) return;
			if (!this.targetCollision) {
				this.valueMinutes = this.selectionStartMinutes;
				this.showSelection = false;
				this.$emit('input', this.valueStart.toString());
			}
		}
	},
}
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

div.my-container::v-deep
	position: relative

div.my-container::v-deep > .my-interval
	overflow: hidden
	box-sizing: border-box
	border-top: 1px solid
	&:first-child
		border-top: none

div.my-container::v-deep > .my-interval > .caption
	margin-left: .2em
	margin-top: .2em

div.my-container::v-deep > .my-event
	box-sizing: border-box
	position: absolute
	left: 36px
	right: 8px
	//border: 1px solid red
	overflow: hidden

div.my-container::v-deep > .my-placeholder
	position: absolute
	left: 36px
	right: 8px
	top: 20px

.theme--light div.my-container::v-deep > .my-interval
	border-color: map-get($material-light, 'dividers')

.theme--dark div.my-container::v-deep > .my-interval
	border-color: map-get($material-dark, 'dividers')

.theme--light .my-interval.disabled::v-deep
	color: map-deep-get($material-light, 'buttons', 'disabled')
	background-color: map-deep-get($material-light, 'buttons', 'focused')
.theme--dark .my-interval.disabled::v-deep
	color: map-deep-get($material-dark, 'buttons', 'disabled')
	background-color: map-deep-get($material-dark, 'buttons', 'focused')
</style>