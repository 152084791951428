var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(!_vm.hideTitle)?_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title || _vm.imodel.Title))]),(_vm.editButtonsTop)?_c('v-spacer'):_vm._e(),(_vm.editButtonsTop && _vm.loading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.editButtonsTop)?_c('edit-buttons',_vm._b({attrs:{"showDelete":(_vm.idInternal > 0 || _vm.params) && (_vm.editButtonOptions.showDelete !== false),"disabled":_vm.loading},on:{"save":function($event){_vm.saveNoClose?_vm.doSave():_vm.doSaveClose()},"delete":_vm.onDelete,"close":_vm.doClose}},'edit-buttons',_vm.editButtonOptions,false)):_vm._e()],1):_vm._e(),(!_vm.hideTitle)?_c('v-divider'):_vm._e(),(_vm.$scopedSlots.default)?_c('div',{style:({maxHeight:_vm.maxContentHeight,overflowX:'auto'})},[_vm._t("default",null,null,_vm.slotScope)],2):_vm._e(),(!_vm.$scopedSlots.default)?_c('v-container',{style:({maxHeight:_vm.maxContentHeight,overflowX:'auto'}),on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();$event.stopPropagation();_vm.saveNoClose?_vm.doSave():_vm.doSaveClose()}}},[_vm._t("before-fields",null,null,_vm.slotScope),_vm._l((_vm.getEditFields),function(f){return [(_vm.$scopedSlots['before-row-right.'+f.name])?_c('v-row',{key:'before-row-right.'+f.name,attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pt-3",style:(_vm.leftColStyle)}),_c('v-col',{staticClass:"pa-1",style:(_vm.rightColStyle)},[_vm._t('before-row-right.'+f.name,null,null,_vm.slotScope)],2)],1):_vm._e(),_vm._t('before-row.'+f.name,null,null,{
					..._vm.slotScope,
					disabled:_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name),
					leftColStyle: _vm.leftColStyle,
					rightColStyle: _vm.rightColStyle,
				}),_c('v-row',{key:'replace-row.'+f.name,attrs:{"no-gutters":"","justify":"center"}},[_vm._t('row.'+f.name,function(){return [_c('v-col',{staticClass:"pt-3",style:(_vm.leftColStyle)},[(_vm.moveLabelLeft(f))?_vm._t('label.'+f.name,function(){return [(f.description)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(_vm._s(f.label)+" "),_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-information-outline")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(f.description)+" ")])]:[_vm._v(" "+_vm._s(f.label)+" ")]]},null,{..._vm.slotScope,disabled:_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name)}):_vm._e()],2),_c('v-col',{staticClass:"pa-1",style:(_vm.rightColStyle)},[_vm._t('field.'+f.name,function(){return [_c('edit-input',{attrs:{"label":_vm.moveLabelLeft(f) ? '' : f.label,"outlined":"","dense":"","name":f.name,"data":_vm.row,"errors":_vm.err,"disabled":_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name)}})]},null,{..._vm.slotScope,field:f,disabled:_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name)})],2),(_vm.thirdCol)?_c('v-col',_vm._b({},'v-col',_vm.thirdColAttrs,false),[_vm._t('third.'+f.name,null,null,{..._vm.slotScope,field:f,disabled:_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name)})],2):_vm._e()]},null,{
						..._vm.slotScope,
						disabled:_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name),
						leftColStyle: _vm.leftColStyle,
						rightColStyle: _vm.rightColStyle,
					})],2),(_vm.$scopedSlots['after-row-right.'+f.name])?_c('v-row',{key:'after-row-right.'+f.name,attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pt-3",style:(_vm.leftColStyle)}),_c('v-col',{staticClass:"pa-1",style:(_vm.rightColStyle)},[_vm._t('after-row-right.'+f.name,null,null,_vm.slotScope)],2)],1):_vm._e(),_vm._t('after-row.'+f.name,null,null,{
					..._vm.slotScope,
					disabled:_vm.readOnly||_vm.isConstrained(f.name)||_vm.isDisabled(f.name),
					leftColStyle: _vm.leftColStyle,
					rightColStyle: _vm.rightColStyle,
				})]}),(_vm.$scopedSlots['after-fields-right'])?_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{staticClass:"pt-3",style:(_vm.leftColStyle)}),_c('v-col',{staticClass:"pa-1",style:(_vm.rightColStyle)},[_vm._t("after-fields-right",null,null,_vm.slotScope)],2)],1):_vm._e(),_vm._t("after-fields",null,null,{
				..._vm.slotScope,
				leftColStyle: _vm.leftColStyle,
				rightColStyle: _vm.rightColStyle,
			})],2):_vm._e(),(_vm.editButtonsBottom)?_c('v-divider'):_vm._e(),(_vm.editButtonsBottom)?_c('v-toolbar',{attrs:{"flat":""}},[_c('edit-buttons',_vm._b({staticClass:"flex-grow-1",attrs:{"showDelete":(_vm.idInternal > 0 || _vm.params) && (_vm.editButtonOptions.showDelete !== false),"disabled":_vm.loading},on:{"save":function($event){_vm.saveNoClose?_vm.doSave():_vm.doSaveClose()},"delete":_vm.onDelete,"close":_vm.doClose},scopedSlots:_vm._u([{key:"right-inside",fn:function(){return [(_vm.loading)?_c('v-progress-circular',{staticClass:"mr-2",attrs:{"indeterminate":"","color":"primary"}}):_vm._e()]},proxy:true}],null,false,727954798)},'edit-buttons',_vm.editButtonOptions,false))],1):_vm._e(),_c('ConfirmDlg',{ref:"deleteConfirm",attrs:{"label-confirm":"Изтрий","color-confirm":"red darken-1","icon-confirm":"mdi-delete"}},[_vm._v(" Моля потвърдете изтриването на записа ")]),_c('v-snackbar',{attrs:{"color":"red","timeout":-1,"multiLine":false},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.active = false}}},'v-btn',attrs,false),[_vm._v(" Затвори ")])]}}]),model:{value:(_vm.snackbar.active),callback:function ($$v) {_vm.$set(_vm.snackbar, "active", $$v)},expression:"snackbar.active"}},_vm._l((_vm.snackbar.text),function(t,i){return _c('div',{key:i},[_vm._v(_vm._s(t))])}),0),_vm._t("after",null,null,_vm.slotScope)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }