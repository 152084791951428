<!--
Transparent component, to be used with <quick-search> children.
Gives each <quick-search> its own rule to play with
-->

<template>
	<div style="display: contents;">
		<slot/>
	</div>
</template>

<script>
export default {
	provide() {
		return {
			quickSearchSet: this,
		}
	},
	props: {
		filterGroup: Object,
	},
	data(){
		return {
			rules: [],
		}
	},
	watch: {
		rules: {
			deep: true,
			handler(val) {
				this.$emit('update:filterGroup', {
					rules: val,
				})
			},
		}
	},
	methods: {
		// called by children to create a new rule and return its index
		initRule(rule) {
			this.$set(this.rules, this.rules.length, rule || {});
			return this.rules.length - 1;
		},
		// called by children to get an already initialized rule
		getRule(index) {
			return this.rules[index];
		},
		// called by children to discard a rule
		endRule(index) {
			// don't actually remove the rule as this will shift the indices
			this.rules[index] = null;
		},
	},
}
</script>