
import IModelMixin from "./IModelMixin";
import {debounceAsync} from "@/ittijs/utils";

export default {
	mixins: [IModelMixin],

	data(){
		if (!this.imodel.loaded) {
			this.imodel?.fieldsAsync?.then(() => this.imodelLoaded = true);
		}
		return {
			items:[],
			totalItems: -1,
			loading: false,
			options: {
				mustSort: true,
				itemsPerPage: 20,
			},
			imodelLoaded: this.imodel.loaded,
			getDataFromApiDebounce: debounceAsync(this.getDataFromApi, this.apiDebounceTime),
		}
	},

	props:{
		params: Object,
		hiddenCols: {},
		filter:Object,
		itemKey: {
			type: [String, Array],
			default: 'id',
		},
		disablePagination: Boolean,
		apiDebounceTime: {
			type: Number,
			default: 200,
		},
	},

	computed: {
		itemKeyList(){
			if (typeof this.itemKey === 'string') {
				return this.itemKey.split(',');
			}
			else if (Array.isArray(this.itemKey)) {
				return this.itemKey;
			}
			else {
				return [];
			}
		},
	},

	watch: {
		options: {
			handler () {
				this.getDataFromApiDebounce()
			},
			deep: true,
		},

		params: {
			handler () {
				this.getDataFromApiDebounce()
			},
			deep: true,
		},

		filter: {
			handler () {
				this.getDataFromApiDebounce()
			},
			deep: true,
		}
	},

	methods: {

		itemToKey(item){
			const result = {};
			for (const k of this.itemKeyList) {
				result[k] = item[k];
			}
			return result;
		},

		getListOptions(){
			const { sortBy, sortDesc, page, itemsPerPage } = this.options;
			const Options = {}

			if(!this.disablePagination && itemsPerPage>0){
				Options.offset = (page-1)*itemsPerPage;
				Options.limit = itemsPerPage;
			}

			Options.cols = [
				this.itemKey,
				...this.hedr.filter(h=>!h.skipInList).map(h=>h.value),
				...this.hedrHidden.map(h=>h.value)
			];

			if(sortBy.length>0){
				Options.sort=[];
				sortBy.forEach((col, i) => {
					if (!Options.cols.includes(col)) return;
					Options.sort.push(col + (sortDesc[i] ? '-DESC' : ''));
				});
			}

			Options.cols = [...new Set(Options.cols)];

			Options.filter = this.filter;

			Options.params = this.params;

			//console.log('Current Filter', this.filter);

			return Options;
		},

		async getDataFromApi(){
			this.loading = true;
			this.selected = [];

			const data = await this.imodel.getList(this.getListOptions());

			this.items = data.items.map((item)=>{
				const row = this.imodel.formatData(item);
				row["__raw"] = item;
				return row;
			});
			//console.log('formatted list data', this.itemsFormatted);
			if (data.total) {
				this.totalItems = data.total;
			}
			else {
				this.totalItems = -1;
			}
			this.loading = false;
		},

		refresh() {
			this.getDataFromApiDebounce();
		},

	},

}
