<template>
	<div v-if="imodelLoaded">

		<ConfirmDlg
			ref="dlgDelete"
			label-confirm="Delete"
			color-confirm="red darken-1"
			icon-confirm="mdi-delete"
		>
			Сигурни ли сте, че искате да изтриете
				<template v-if="hideSelect">
					този запис?
				</template>
				<template v-else>
					{{selected.length}} {{selected.length > 1 ? 'записа' : 'запис'}}?
				</template>
		</ConfirmDlg>

		<v-data-table
			:loading="loading"
			dense
			ref="dt"
			v-bind="$attrs"
			v-on="$listeners"
			v-model="selected"
			:headers="hedr"
			:items="items"
			:item-key="itemKey"
			:options.sync="options"
			:server-items-length="totalItems"
			:show-select="!hideSelect"
			:footer-props="footerProps"
			:disable-pagination="disablePagination"
			:hide-default-footer="disablePagination"
		>

			<template v-slot:top="{ pagination, options, updateOptions }">
				<v-data-footer
					v-bind="footerProps"
					:pagination="pagination"
					v-if="!disablePagination"
					:options="options"
					@update:options="updateOptions"
					items-per-page-text="$vuetify.dataTable.itemsPerPageText"
				/>
				<v-divider
					v-if="!disablePagination"
				/>
				<v-container fluid v-if="topControlsVisible">
					<v-row class="pr-2">
						<div v-bind:style="{visibility: selected.length ? 'visible' : 'hidden' }">
							<v-icon class="ml-5 mt-3" style="transform: rotate(90deg)">mdi-subdirectory-arrow-right</v-icon>
							{{ selected.length }} selected:
							<v-tooltip v-if="!hideDelete" top color="red">
								<template v-slot:activator="{ on, attrs }">
									<v-btn icon v-bind="attrs" v-on="on" @click="onDeleteList">
										<v-icon color="red">mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Delete</span>
							</v-tooltip>
							<!-- @slot Actions for selected rows -->
							<slot name="select-actions" :selected="selected"></slot>
						</div>
						<v-spacer/>
						<!-- @slot Top right actions -->
						<slot name="top-actions"></slot>
						<download-btn
							v-if="!hideExport"
							small
							:rest-service="onExport"
							color="primary"
							class="my-1"
						>
							<v-icon left>mdi-microsoft-excel</v-icon>
							Excel
						</download-btn>
					</v-row>
				</v-container>
				<v-divider  v-if="topControlsVisible" />
			</template>

			<template v-show="showLeftActions" v-slot:item.__left="{ item }">
				<div style="white-space: nowrap">
					<v-btn v-if="!hideEdit" icon @click="$emit('edit', item)"
						><v-icon>mdi-pencil</v-icon></v-btn
					>
					<v-btn v-if="hideSelect && !hideDelete" icon @click="onDelete(item)"
						><v-icon>mdi-delete</v-icon></v-btn
					>
					<v-btn icon v-for="(evtype, icon) in leftActions" v-bind:key="icon" @click="$emit(evtype, item)">
						<v-icon>{{icon}}</v-icon>
					</v-btn>
					<!-- @slot Left per-item actions (next to edit/delete) -->
					<slot name="left-actions" :item="item" />
				</div>
			</template>

			<template v-show="showRightActions" v-slot:item.__right="{ item }">
				<div style="white-space: nowrap">
					<v-btn icon v-for="(evtype, icon) in rightActions" v-bind:key="icon" @click="$emit(evtype, item)">
						<v-icon>{{icon}}</v-icon>
					</v-btn>
					<!-- @slot Right per-item actions -->
					<slot name="right-actions" :item="item" />
				</div>
			</template>

			<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
				><slot :name="slot" v-bind="scope"
			/></template>

		</v-data-table>
	</div>
</template>

<script>
import ListMixin from "./ListMixin";
import ConfirmDlg from "./ConfirmDialog";
import DownloadBtn from "@/ittijs/components/DownloadBtn";

export default {
	inheritAttrs: false,

	mixins: [ListMixin],

	components: {
		DownloadBtn,
		ConfirmDlg,
	},

	props: {
		headers: {},
		headersSkip: {},
		hideSelect: Boolean,
		hideEdit: Boolean,
		hideDelete: Boolean,
		hideExport: Boolean,
		leftActions: Object, // key is icon, value is event to throw
		rightActions: Object, // key is icon, value is event to throw
		leftActionsTitle: {
			type: String,
			default: "",
		},
		rightActionsTitle: {
			type: String,
			default: "",
		},
	},

	data: ()=>({
		selected: [],
		footerProps: {
			itemsPerPageOptions: [10,20,50,100,-1],
			// pagination: { this breaks reactivity between top and bottom
			//   pageStop: -1
			// },
		},
	}),

	computed: {
		hedr() {
			const h = this.fieldList(this.headers) || Object.values(this.imodel.getListHeaders()) || [];
			const skip = this.fieldList(this.headersSkip) || [];
			let result = [];
			if (this.showLeftActions) result = [...result, { value: "__left", text: this.leftActionsTitle, sortable: false, skipInList: true }];
			result = [...result, ...h.filter(f=>!skip.some(s=>s.value===f.value))];
			if (this.showRightActions) result = [...result, { value: "__right", text: this.rightActionsTitle, sortable: false, skipInList: true, align: "right" }];
			//result = [...result, { value: 'data-table-expand', skipInList: true }]; kakvo e tova?...
			return result;
		},
		hedrHidden(){
			return this.fieldList(this.hiddenCols) || [];
		},
		/**
		 * whether there is anything to be shown in the top toolbar
		 */
		topControlsVisible(){
			if (this.$slots['top-actions']) {
				return true;
			}
			if (!this.hideExport) {
				return true;
			}
			if (this.hideSelect) {
				return false;
			}
			if (!this.hideDelete) {
				return true;
			}
			if (!this.$scopedSlots['select-actions']) {
				return false;
			}
			return true;
		},
		showLeftActions(){
			if (typeof(this.leftActions)==='object' && Object.keys(this.leftActions).length) {
				return true;
			}
			if (this.$scopedSlots['left-actions']) {
				return true;
			}
			if (!this.hideEdit) {
				return true;
			}
			if (this.hideSelect && !this.hideDelete) {
				return true;
			}
			return false;
		},
		showRightActions(){
			if (typeof(this.rightActions)==='object' && Object.keys(this.rightActions).length) {
				return true;
			}
			if (this.$scopedSlots['right-actions']) {
				return true;
			}
			return false;
		},
	},

	methods: {
		getSelectedKeys(){
			return this.selected.map(this.itemToKey);
		},
		fieldList(list){
			let h;
			if (typeof list === "string") {
				h = this.imodel.getListHeaders(list);
				h = Object.values(h);
			}
			else if (Array.isArray(list)) {
				h = list.map(f => {
					if (typeof f === "string") return this.imodel.getListHeader(f);
					return f;
				});
			}
			else if (typeof list === "object") {
				h = Object.values(list);
			}
			else {
				return null;
			}
			return h;
		},
		onDelete(item) {
			this.$refs.dlgDelete.open().then((res) => {
				if (res) {
					this.imodel.delete(this.itemToKey(item)).then(() => {
						this.getDataFromApi();
					});
				}
			});
		},
		onDeleteList(){
			const items = this.getSelectedKeys();
			this.$refs.dlgDelete.open().then((res) => {
				if (res) {
					this.imodel.deleteList(items).then(() => {
						this.getDataFromApi();
					});
				}
			});
		},
		async onExport(){
			return await this.imodel.exportList(this.getListOptions());
		},
	},
};
</script>

<style>
tr.v-data-table__expanded__row > * {
	/* todo - accent color */
}
</style>