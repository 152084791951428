<template>
	<v-select
		v-bind="$attrs"
		v-on="$listeners"
		:value="value"
		@input="$emit('input', $event)"
		:items="filteredItems"
		item-text="title"
		item-value="id"
	>
	</v-select>
</template>

<script>
export default {
	components: {
	},
	props: {
		value: {},
		service: {}, // not used, only for sync
		items: Array,
		type: String,
		actions: Array,
	},
	computed: {
		filteredItems(){
			return this.items.filter(item => {
				if (this.actions && Array.isArray(this.actions)) {
					if (this.actions.indexOf(item.action) === -1) return false;
				}
				// archived services are removed unless already selected
				if (item.is_archived==='1' && this.value != item.id) return false;
				return item.type == this.type || this.type == '';
			});
		},
		selectedItem(){
			return this.items && this.items.find(e => e.id == this.value);
		},
	},
	watch: {
		selectedItem: {
			immediate: true,
			handler(){
				this.$emit('update:service', this.selectedItem);
			},
		},
	},
}
</script>