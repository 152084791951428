<template>
	<div :style="style" class="grey lighten-2" v-if="!hideEmpty || eventsFiltered.length">
		<template v-if="$scopedSlots.tooltip">
			<v-tooltip
				top
				v-for="(job, index) in eventsFiltered"
				:key="index"
				color="grey darken-4"
			>
				<template #activator="{attrs,on}">
					<div
						:style="getEventStyle(job)"
						class="primary lighten-2"
						v-bind="attrs"
						v-on="on"
						@click.stop="$emit('jobclick', job)"
					/>
				</template>
				<slot name="tooltip" v-bind="{job}"/>
			</v-tooltip>
		</template>
		<template v-else>
			<div
				v-for="(job, index) in events"
				:key="index"
				:style="getEventStyle(job)"
				class="primary lighten-2"
				@click.stop="$emit('jobclick', job)"
			/>
		</template>
	</div>
</template>
<script>
import {TimeOfDay} from "@/ittijs/utils";

export default {
	props: {
		events: Array,
		height: {
			type: Number,
			default: 16,
		},
		start: {
			type: String,
			default: '00:00',
		},
		end: {
			type: String,
			default: '23:59',
		},
		hideEmpty: Boolean,
	},
	computed: {
		startTime(){
			return TimeOfDay.fromString(this.start);
		},
		endTime(){
			return TimeOfDay.fromString(this.end);
		},
		style(){
			return {
				borderWidth: '1px',
				borderStyle: 'solid',
				position: 'relative',
				overflow: 'hidden',
				height: `${this.height}px`,
			}
		},
		eventsFiltered() {
			// remove jobs without start/end time
			return this.events.filter(e => e.start !== null && e.end !== null);
		},
	},
	methods: {
		timeToPercent(time){
			if (!time) return null;
			const start = this.startTime?.minutes();
			if (start === null) return null;
			const duration = this.endTime?.minutes() - start;
			if (!duration) return null;
			time = time.minutes() - start;
			return 100 * time / duration;
		},
		getEventStyle(event){
			return {
				position: 'absolute',
				top: 0,
				bottom: 0,
				left: `${this.timeToPercent(TimeOfDay.fromString(event.start))?.toFixed(2) ?? 0}%`,
				right: `${100 - this.timeToPercent(TimeOfDay.fromString(event.end))?.toFixed(2) ?? 100}%`,
			}
		},
	},
}
</script>