import ITTIModel from "@/ittijs/ITTIModel";

export default class extends ITTIModel {
	constructor() {
		super('MGSchedule');
	}
	getCalendarData(utility, start, end){
		return this.fetch('getCalendarData', {utility, start, end});
	}
	getDayData(utility, date){
		return this.fetch('getDayData', {utility, date});
	}
}