<template>
	<span>{{ imodel.getLabel(name) }}</span>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
export default {
	mixins: [IModelMixin],
	props: ['name'],
}
</script>