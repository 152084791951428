var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"my-container",style:({
		width: `${_vm.width??250}px`,
	}),on:{"mouseenter":_vm.onMouseEnter,"mousemove":_vm.onMouseMove,"mouseleave":_vm.onMouseLeave,"click":_vm.onClick}},[_vm._l((_vm.intervals),function(item,index){return _c('div',{key:'interval'+index,class:{
			'my-interval': true,
			disabled: item.last,
		},style:({
			height: `${ _vm.intervalHeight }px`,
		})},[_c('div',{staticClass:"caption"},[_vm._v(_vm._s(item.time))])])}),_vm._l((_vm.eventsDecorated),function(item,index){return _c('v-sheet',{key:'event'+index,staticClass:"my-event",style:(item.style),attrs:{"elevation":"1","rounded":"","color":_vm.$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'},on:{"click":function($event){return _vm.$emit('click:event', item)}}},[_vm._t("event",function(){return [_vm._v(" "+_vm._s(item.start.toString())+" - "+_vm._s(item.end.toString())+" ")]},null,{start: item.start, end: item.end, event: item})],2)}),(_vm.valueMinutes !== null)?_c('v-sheet',_vm._b({staticClass:"my-placeholder",attrs:{"elevation":"1","rounded":"","color":_vm.$vuetify.theme.dark ? 'success darken-2' : 'success lighten-2'}},'v-sheet',_vm.valueAttrs,false),[(_vm.valueMinutes !== null)?_c('v-btn',{attrs:{"fab":"","x-small":"","absolute":"","top":"","right":"","color":"error"},on:{"click":function($event){$event.stopPropagation();return _vm.onClickClear.apply(null, arguments)}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.valueStart?.toString())+" - "+_vm._s(_vm.valueEnd?.toString())+" ")],1):_vm._e(),(_vm.showSelection)?_c('v-sheet',_vm._b({staticClass:"my-placeholder",staticStyle:{"pointer-events":"none"},attrs:{"elevation":"2","rounded":""}},'v-sheet',_vm.placeholderAttrs,false),[_vm._v(" "+_vm._s(_vm.selectionStart?.toString())+" - "+_vm._s(_vm.selectionEnd?.toString())+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }