<template>
	<v-btn-toggle v-bind="$attrs" v-on="$listeners" v-model="value" @change="onChange">
		<slot/>
	</v-btn-toggle>
</template>

<script>
/**
 * Simple v-btn-toggle that doesn't have a selection state
 */
export default {
	data(){
		return {
			value: null,
		}
	},
	methods: {
		async onChange(){
			await this.$nextTick();
			this.value = null;
		},
	},
}
</script>