<template>
	<BaseInput
		:value="data && data[name] || ''"
		:errors="errors && errors[name] || null"
		v-bind="forbind"
		@input="$set(data, name, $event); $emit('input', $event)"
		@change="$set(data, name, $event); $emit('change', $event)"
	>
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
	</BaseInput>
</template>

<script>
import Validators from '../Validators';
import BaseInput from './BaseInput';
import IModelMixin from "../IModelMixin";

export default {
	mixins: [IModelMixin],

	inject: {
		inputGroups: {
			default: null,
		},
	},

	inheritAttrs: false,

	components:{
		BaseInput
	},

	props:{
		name:String,
		data:{},
		errors:{},
		type:String,
		clearable:{
			type:Boolean,
			default:false
		},
	},

	data(){
		return {
			watchers: [],
			options: null,
		}
	},

	created () {
		this.inputGroups && this.inputGroups.regInput('edit', this);
	},

	mounted() {
		if (Array.isArray(this.fld.dependsOn)) {
			for (const dep of this.fld.dependsOn) {
				this.watchers.push(this.$watch('data.'+dep, function(){
					// collect all dependencies and ... todo
					const params = {};
					for (const dep of this.fld.dependsOn) {
						params[dep] = this.data[dep];
					}
					this.onDependencyChange(params);
				}));
			}
		}
	},

	beforeDestroy () {
		this.inputGroups && this.inputGroups.unregInput('edit', this);
		for (const f of this.watchers) {
			f();
		}
		this.watchers = [];
	},

	computed:{

		fld: function(){
			return this.imodel.fields[this.name] || {};
		},

		forbind: function(){
			const type = this.type ? this.type : this.fld.inputType;

			const forbind={
				label: this.fld.label,
				type,
				name: this.name,
				...this.$attrs,
				clearable: (this.clearable || this.fld.nullable) && !this.$attrs.readonly
			};

			if (!this.$attrs.disabled && this.fld.rules) {
				if (!forbind.rules) forbind.rules = [];
				for (const rule in this.fld.rules) {
					const params = this.fld.rules[rule];
					const fn = Validators[rule];
					if (fn) {
						forbind.rules.push(fn.call(Validators, ...params));
					}
				}
			}

			switch (type) {

				case 'decimal':

					if( this.fld.int!=null && !forbind.int ){
						forbind.int = this.fld.int;
					}
					if( this.fld.dec!=null && !forbind.dec ){
						forbind.dec = this.fld.dec;
					}
					break;

				case 'textarea':
					if (parseInt(this.fld.height)>0) {
						forbind.height = this.fld.height;
					}
					break;

				default:
					break;
			}

			// which props to pass from the model field to the input
			const modelProps = [
				//'options', handled separately
				'multiple',
			];

			if (!this.$attrs.disabled) {
				modelProps.push(...[
					'required',
					'pattern',
					'minlength',
					'maxlength',
					'min',
					'max',
					'minstring',
					'maxstring',
					'mincount',
					'maxcount',
				]);
			}

			for (const p of modelProps) {
				if (this.fld[p] && !forbind[p]) {
					forbind[p] = this.fld[p];
				}
			}

			// handle options separately because of this.options
			if (this.options) {
				forbind.options = this.options;
			}
			else if (this.$attrs.options) {
				forbind.options = this.$attrs.options;
			}
			else if (this.fld.options) {
				forbind.options = this.fld.options;
			}

			return forbind;
		},
	},

	methods:{
		onDependencyChange(params){
			if (this.fld.optionsMethod) {
				this.imodel.fetch(this.fld.optionsMethod, params).then(res=>{
					this.options = res || [];
				});
			}
		},
	},

}
</script>
