<template>
	<div style="display: inline-block" :class="dynamicClass">{{ formatted }}</div>
</template>

<script>
import {phpDate} from "@/ittijs/utils";
import NormalizedDateTimePropMixin from "@/ittijs/NormalizedDateTimePropMixin";

export default {
	mixins: [
		NormalizedDateTimePropMixin('date', 'dateNormalized'),
	],
	name: "DateFormat",
	props: {
		format: {
			type: String,
			default: window.ITTIConfig.dateFormat,
		},
		pastClass: {}, // if date is in the past
		futureClass: {}, // if date is in the future
	},
	computed: {
		formatted() {
			return this.dateNormalized && phpDate(this.format, this.dateNormalized) || '';
		},
		/**
		 * Взима от pastClass или futureClass, в зависимост от date
		 */
		dynamicClass() {
			if (!this.dateNormalized) return null;
			const today = new Date;
			today.setHours(0,0,0,0);
			if (this.dateNormalized.getTime() < today.getTime()) {
				return this.pastClass;
			}
			else if (this.dateNormalized.getTime() > today.getTime()) {
				return this.futureClass;
			}
			return null;
		}
	},
}
</script>