<template>
	<div style="display: inline-block">
		<slot
			v-bind="{start: formattedStart, end: formattedEnd, duration: formattedDuration}">
			{{ formattedStart }} - {{ formattedEnd }}
		</slot>
	</div>
</template>

<script>
import {TimeOfDay} from "@/ittijs/utils";

export default {
	props: {
		start: String,
		end: String,
		duration: Number,
	},
	computed: {
		parsedStart() {
			return this.start && TimeOfDay.fromString(this.start) || null;
		},
		parsedEnd() {
			return this.end && TimeOfDay.fromString(this.end) || null;
		},
		calcDuration() {
			return this.parsedStart && this.parsedEnd && this.parsedEnd.minutes() - this.parsedStart.minutes() || null;
		},
		calcEnd() {
			return this.parsedStart && this.duration && this.parsedStart.addMinutes(this.duration) || null;
		},
		formattedStart() {
			return this.parsedStart?.toString();
		},
		formattedEnd() {
			return (this.parsedEnd || this.calcEnd)?.toString();
		},
		formattedDuration() {
			return this.duration || this.calcDuration;
		},
		valid() {
			return Boolean(this.formattedStart && this.formattedEnd);
		},
	},
}
</script>