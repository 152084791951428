<template>
	<div class="d-flex align-center" style="min-height: 30px;">
		<span class="mr-1">Дублиран:</span>
		<span v-if="!loading && result===false" class="green--text">НЕ</span>
		<span v-if="!loading && result===true" class="deep-orange--text font-weight-bold">ДА</span>
		<v-progress-circular v-if="loading" class="ml-2" size="24" color="primary" indeterminate />
	</div>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import {debounceAsync} from "@/ittijs/utils";

export default {
	mixins: [IModelMixin, SelectedUtilityMixin],
	props: [
		'number',
		'id',
	],
	data(){
		return {
			loading: false,
			result: null,
			loadDebounced: debounceAsync(this.load, 400),
		}
	},
	mounted() {
		this.load();
	},
	watch: {
		number(v){
			if (typeof v === 'undefined' || v === null || v == '') {
				this.result = null;
			}
			else {
				this.loadDebounced();
			}
		},
	},
	methods: {
		async load(){
			if (typeof this.number === 'undefined' || this.number === null || this.number == '') {
				this.result = null;
			}
			else {
				this.loading = true;
				this.result = !! await this.imodel.isDeviceNDuplicate(
					this.selectedUtility,
					this.number,
					this.id
				);
				this.loading = false;
			}
		},
	},
}
</script>