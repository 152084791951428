<!--
wrapper for the device data list control
получава всички УП-та за имота и ID на уред, решава какво точно да
подаде на <up-device-alert>
-->

<template>
	<up-device-alert
		v-bind="$attrs"
		:list="filteredUPList"
		@reload="$emit('reload')"
	/>
</template>

<script>
import UpDeviceAlert
	from "@/ittijs/components/UvedomitelniPisma/UpDeviceAlert.vue";

/* eslint-disable @typescript-eslint/camelcase */

export default {
	components: {UpDeviceAlert},
	props: {
		upList: Array,
		deviceId: Number,
	},
	computed: {
		filteredUPList(){
			// списък УП само за конкретния уред
			const result = [];
			for (const item of this.upList) {
				for (const device of item.devices) {
					if (device.is_fixed) continue;
					if (device.id !== this.deviceId) continue;
					result.push({
						id              : item.id,
						created_time    : item.created_time,
						received        : item.received,
						expiration_days : item.expiration_days,
						expires         : item.expires,
						file            : item.file,
						file_receipt    : item.file_receipt,
						device_N        : device.device_N,
						reason          : device.reason,
					});
				}
			}
			return result;
		},
	},
}
</script>