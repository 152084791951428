<!--
Use this when you don't have a session ID on hand.
The session ID will be fetched from the server before the browser is redirected to the account link.
-->

<template>
	<v-btn v-bind="$attrs" v-on="$listeners" @click="onClick">{{ label }}</v-btn>
</template>

<script>
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import ModelImotData from "@/views/imot_data/Model";
import {RolesEnum} from "@/ittijs/Authorization";

const imotDataModel = ITTIModelProvider.getModel(ModelImotData);

export default {
	inject: [
		'authorization',
	],
	props: {
		utility: {},
		station: {},
		imot: {},
		targetBlank: Boolean,
		label: {
			type: String,
			default: 'Детайли',
		},
	},
	computed: {
		routeName() {
			if (this.authorization.hasRole(RolesEnum.OTCHETI_FULL)) {
				return 'account';
			}
			else if (this.authorization.hasRole(RolesEnum.FRONTDESK)) {
				return 'frontdesk';
			}
			else {
				return null;
			}
		},
	},
	methods: {
		async onClick() {
			if (!this.routeName) return;
			const res = await imotDataModel.searchImotSession(this.utility, this.station, this.imot);
			if (!res) throw "Няма сесия";
			const route = {
				name: this.routeName,
				params: {
					utility: this.utility,
					session: res.session,
					station: res.station,
					imot: this.imot,
				},
			};
			if (this.targetBlank) {
				window.open(
					this.$router.resolve(route).href,
					'_blank'
				);
			}
			else {
				await this.$router.push(route);
			}
		},
	},
}
</script>