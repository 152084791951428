// import Validators from '@/ittijs/Validators';
import EditInput from '@/ittijs/Inputs/EditInput';
import ConfirmDlg from '@/ittijs/ConfirmDialog';
import EditButtons from '@/ittijs/EditButtons.vue';
import IModelMixin from "@/ittijs/IModelMixin";

export default {

	components: {
		EditInput,
		ConfirmDlg,
		EditButtons,
	},

	mixins: [
		IModelMixin,
	],

	data: function() {
		return {
			// Validators,
			row: {},
			snackbar: {
				active: false,
				text: '',
			},
			loading: false,
			idInternal: null,
			valid: true,
			err: {},
		}
	},

	props: {
		// id and params are mutually exclusive
		id: String,
		params: Object,
		title: String,
		hideTitle: Boolean,

		data: Object, // not used, only for sync (can't use refs in computed props, so can't always get .row from outside)
		dataAppend: Object, // for additional data from outside, submitted along with this.data
		fields: [String,Array], // explicit list of fields to show and in what order
		fieldsCallback: Function, // receives the row and returns array of field names to render
		hiddenFieldsCallback: Function, // receives the row and returns array of field names to hide
		constrains: {
			type: Object,
			default: ()=>({}),
		},
		readOnly: Boolean,
		disabledFields: [String, Array], // which fields should be disabled
		hiddenFields: [String, Array], // override skipInEdit for particular fields
		hideConstrained: Boolean,
		init: {
			type: Object,
			default: ()=>({}),
		},
		editButtons: {
			type: String,
			default: 'bottom',
		},
		editButtonOptions: {
			type: Object,
			default: ()=>({}),
		},
		maxContentHeight: String, // css height for the form elements container (useful for dialogs)
	},

	computed: {
		editButtonsTop(){
			return this.editButtons === 'top' || this.editButtons === 'both';
		},
		editButtonsBottom(){
			return this.editButtons === 'bottom' || this.editButtons === 'both';
		},
		getEditFields(){
			let fields = [];
			if (typeof this.fields === 'string') {
				fields = this.fields.split(',');
			}
			else if (Array.isArray(this.fields) && this.fields.length) {
				fields = this.fields;
			}
			else if (typeof this.fieldsCallback === 'function') {
				fields = this.fieldsCallback(this.row);
			}
			else {
				fields = Object.keys(this.imodel.fields);
			}
			const result = [];
			for (const fname of fields) {
				const f = this.imodel.fields[fname];
				if (!f) continue;
				if (f.skipInEdit) continue;
				if (this.isHidden(f.name)) continue;
				if (this.hideConstrained && this.isConstrained(f.name)) continue;
				result.push(f);
			}
			return result;
		},
		cacheHiddenFieldsCallback(){
			if (typeof this.hiddenFieldsCallback === 'function') {
				return this.hiddenFieldsCallback(this.row);
			}
			return [];
		},
		slotScope(){
			return {
				id: this.id,
				params: this.params,
				err: this.err,
				row: this.row,
				imodel: this.imodel,
				loading: this.loading,
			};
		},
	},

	watch: {
		id(id){
			this.idInternal = id;
			if (id!==null || this.params) {
				this.edit();
			}
		},
		row: {
			deep: true,
			handler(val){
				this.$emit('update:data', val);
			},
		},
	},

	mounted(){
		this.idInternal = this.id;
		if (this.id!==null || this.params) {
			this.edit();
		}
	},

	methods: {

		isNameInProp(name, prop) {
			if (!prop) return false;
			if (Array.isArray(prop)) {
				return prop.some(val => val === name);
			}
			if (typeof prop === 'string') {
				return prop.split(',').some(val => val === name);
			}
			return false;
		},

		/**
		 * Whether this field appears in the 'constrains' prop
		 * A constrained field is disabled and may be hidden if hideConstrained is true
		 * @param field
		 * @return {boolean}
		 */
		isConstrained(field){
			return Object.prototype.hasOwnProperty.call(this.constrains, field);
			//return this.constrains.hasOwnProperty(field);
		},

		/**
		 * Whether this field appears in the 'disabledFields' prop
		 * @param field
		 */
		isDisabled(field) {
			return this.isNameInProp(field, this.disabledFields);
		},

		/**
		 * Whether this field appears in the 'hiddenFields' prop or in the result of hiddenFieldsCallback
		 * @param field
		 */
		isHidden(field) {
			if (this.isNameInProp(field, this.hiddenFields)) return true;
			if (this.cacheHiddenFieldsCallback.some(f => f===field)) return true;
			return false;
		},

		getFormData(){
			const result = {};
			for (const input of this.getInputGroup('edit')) {
				if (input.name && !this.isDisabled(input.name)) {
					if (typeof this.row[input.name] === 'undefined') {
						// JSON removes keys with undefined value
						result[input.name] = null;
					}
					else {
						result[input.name] = this.row[input.name];
					}
				}
			}
			return {...result, ...(this.dataAppend || {}), ...this.constrains};
		},

		modelSaveCallback(res) {
			if (res.status==='OK') {
				this.idInternal = res.id; // not guaranteed to exist
				this.row = res.row;
				return true;
			}
			else if (res.status==='ERROR') {
				if (res.errors && res.errors.form && res.errors.form.length > 0) {
					this.snackbar.text = res.errors.form;
				}
				else {
					this.snackbar.text = ['Моля проверете отбелязаните полета и опитайте отново'];
				}
				this.snackbar.active = true;
				this.err = (res.errors && res.errors.field || {});
				return false;
			}
		},

		doSave(){
			if (this.readOnly) return;
			if (!this.valid) {
				this.$refs.form.validate();
				return;
			}
			this.loading = true;
			const params = this.params || {id:this.idInternal};
			return this.imodel.save(params, this.getFormData())
				.finally(()=>{ this.loading = false })
				.then(res=>{
					if (this.modelSaveCallback(res)) {
						this.$emit('save', this.params || {id:this.idInternal});
					}
				})
				;
		},

		doSaveClose(){
			if (this.readOnly) return;
			if (!this.valid) {
				// alert('form not valid');
				this.$refs.form.validate();
				return;
			}
			this.loading = true;
			const params = this.params || {id:this.idInternal};
			return this.imodel.save(params, this.getFormData())
				.finally(()=>{ this.loading = false })
				.then(res=>{
					if (this.modelSaveCallback(res)) {
						this.$emit('saveclose', this.params || {id:this.idInternal});
					}
				})
				;
		},

		doDelete(){
			this.loading = true;
			const params = this.params || {id:this.idInternal};
			return this.imodel.delete(params).finally(()=>{ this.loading = false }).then(res=>{
				if (res.status==='ERROR') {
					if (res.errors && res.errors.form && res.errors.form.length > 0) {
						this.snackbar.text = res.errors.form;
					}
					else {
						this.snackbar.text = ['Моля проверете отбелязаните полета и опитайте отново'];
					}
					this.snackbar.active = true;
					this.err = (res.errors && res.errors.field || {});
					return false;
				}
				else {
					this.$emit('delete');
					return true;
				}
			});
		},

		doClose(){
			this.snackbar.active = false;
			this.snackbar.text = '';
			this.err = {};
			this.idInternal = null;
			this.row = {};
			this.$emit('close');
		},

		edit(){
			this.$refs.form.resetValidation();
			this.snackbar.active = false;
			this.snackbar.text = '';
			this.err = {};

			if (this.params && Object.keys(this.params).length) {
				this.loading = true;
				this.imodel.getRow(this.params)
					.then((row)=>{
						if (row===null) {
							this.row = {...this.init, ...this.constrains};
						}
						else {
							this.row = row;
						}
						// this.$refs.form.resetValidation();
					})
					.finally(()=>{
						this.loading = false;
						this.$emit('loaded', {row: this.row});
					})
				;
			}
			else if(parseInt(this.idInternal)>0){
				this.loading = true;
				this.imodel.getRow({id:this.idInternal})
					.then((row)=>{
						this.row = row;
						// if row is null, do something? shouldn't edit
						// this.$refs.form.resetValidation();
					})
					.finally(()=>{
						this.loading = false;
						this.$emit('loaded', {row: this.row});
					})
				;
			}
			else {
				this.row = {...this.init, ...this.constrains};
				this.$emit('loaded', {row: this.row});
				// this.$refs.form.resetValidation();
			}
		},

		onDelete(){
			if (this.idInternal > 0 || this.params) {
				this.$refs.deleteConfirm.open().then(res=>{
					if (res) {
						this.doDelete().then(res=>{
							if (res) this.doClose()
						});
					}
				})
			}
		},

	},

}
