<template>
	<v-tooltip v-bind="$attrs">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs }">
				<v-icon v-bind="attrs" v-on="on" :color="iconColor">{{ icon }}</v-icon>
			</slot>
		</template>
		<slot/>
	</v-tooltip>
</template>

<script>
export default {
	props: ['icon', 'iconColor'],
}
</script>