<template>
	<span>{{ formatted }}</span>
</template>

<script>
import {formatDateTime} from "@/ittijs/utils";

export default {
	name: "DateTimeFormat",
	props: {
		datetime: String,
		format: {
			type: String,
			default: window.ITTIConfig.dateTimeFormat,
		},
	},
	computed: {
		formatted() {
			return this.datetime && formatDateTime(this.datetime, this.format) || '';
		},
	},
}
</script>