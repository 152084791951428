<template>
  <BaseInput :value="value.value" @input="input" v-bind="forbind" :clearable="clearable">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"><slot :name="slot" v-bind="scope"/></template>
  </BaseInput>
</template>

<script>


import BaseInput from './BaseInput';

export default {
  inheritAttrs: false,

  components:{
    BaseInput
  },

  props:{
    name:String,
    value:{
      type: Object,
      default: ()=>({})
    },
    type:String,
    op:String,
    not:{
      type:Boolean,
      default:false
    },
    clearable:{
      type:Boolean,
      default:true
    },


  },


  computed:{

    fld: function(){
      return this.imodel.fields[this.name];
    },


    forbind: function(){

      const type = this.type ? this.type : this.fld.type;

      const forbind={
        label:this.fld.label,
        type,
        ...this.$attrs
      };


      switch (type) {
        case 'boolean':
          forbind.type='yesno';
          break;
        case 'enum':
          forbind.type='select';
          forbind.options = this.fld.options;
          break;

        default:
          break;
      }



      return forbind;
    },
  },

  methods:{
    input(v){

        this.$emit('input', {
          value:v,
          op:this.op,
          field:this.name,
          not:this.not,
        });

    },

  },

  mounted(){
    this.input(this.value.value);
  },


  inject: ['imodel'],


}
</script>
