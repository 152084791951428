import {isoToDate} from "@/ittijs/utils";

/**
 * Create a pair of properties - one prop and one computed.
 * The prop accepts a string, an int timestamp, or a Date object.
 * The computed depends on the prop and is set to either a Date object or null.
 * @param propName
 * @param normalizedName
 */
export default (propName, normalizedName) => ({
  props: {
    [propName]: [String,Date,Number],
  },
  computed: {
    [normalizedName](){
      switch (typeof this[propName]) {
        case "string":
          if (this[propName]==='') return null;
          return isoToDate(this[propName]);
        case "object":
          if (this[propName] instanceof Date) {
            return this[propName];
          }
          return null;
        case "number":
          return new Date(this[propName]);
        default:
          return null;
      }
    },
  },
})
