<template>
	<data-loader
		ref="loader"
		:watch="imot && {imot, jobId} || null"
		:callback="()=>model.getEditData(imot, jobId)"
		:data.sync="data"
	>
		<template #loading>
			<v-progress-linear indeterminate class="ma-4" rounded />
		</template>
		<template #default="{reload}">
			<div v-if="data">
				<v-toolbar dense flat rounded :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" class="mt-2">
					Услуги за уреди
					<v-spacer/>
					<v-menu offset-y left v-if="!readonly">
						<template #activator="{attr,on}">
							<v-btn small v-bind="attr" v-on="on">Нов уред</v-btn>
						</template>
						<v-list dense>
							<v-list-item
								v-for="item in DeviceTypeToMontajServiceType.keys()"
								:key="item"
								@click="addDevice(item)"
							>{{ DeviceTypeLabels.get(item) }}</v-list-item>
						</v-list>
					</v-menu>
				</v-toolbar>
				<v-simple-table dense>
					<thead>
					<tr>
						<th>Тип, Номер, Локация</th>
						<th>Услуга</th>
						<th>Цена</th>
						<th>Изтича на</th>
						<th>Старо</th>
						<th>Отчет</th>
						<th v-if="showDevicePopupBtn"></th>
					</tr>
					</thead>
					<tbody>
					<service-price-sync
						v-for="(d, index) in data.values_device"
						:key="index"
						v-model="data.values_device[index]"
						:services="data.services"
					>
						<template #default="{value, defaultPrice, discount, service, onServiceChange}">
							<tr>
								<td>
									<div v-if="d.__device">
										<v-icon color="green" v-if="d.__device.model && d.__device.model.is_radio==='1'" style="vertical-align: bottom;" title="Радио">mdi-wifi</v-icon>
										{{ d.__device.device.device_N }}<br>
										<span class="grey--text">
											{{ DeviceTypeLabels.get(d.__device.device.device_type_code) }}<br>
											{{ d.__device.device.room }}
										</span>
									</div>
								</td>
								<td valign="middle" class="py-2" width="400">
									<service-select-base
										:items="data.services"
										:type="DeviceTypeToMontajServiceType.get(d.__device && d.__device.device.device_type_code || d.dev_new__device_type_code)"
										:actions="d.__device ? ['update','replace'] : ['insert']"
										v-model="value.service_id"
										:disabled="readonly"
										@change="onServiceChange"
										style="width: 100%; max-width: 300px;"
										outlined dense hide-details clearable
									/>
									<ServiceDecline
										dense
										v-if="value.service_id"
										:disabled="readonly || !isMontajnik"
										v-model="value.is_declined"
										:reason.sync="value.decline_reason"
										@change="onServiceChange"
									/>
								</td>
								<td nowrap>
									{{ defaultPrice }}<br>
									<v-text-field
										v-model="value.price"
										:disabled="!value.service_id || getBoolean(value.is_declined) || readonly"
										dense hide-details outlined style="width: 100px;"
										@focus="$event.target.select()"
										inputmode="decimal"
									/>
									<div v-if="discount">ТО: {{ discount }}</div>
								</td>
								<td>
									<date-format
										class="ma-1"
										v-if="d.__device"
										:date="d.__device && d.__device.device.reinstall_date"
										past-class="red white--text pa-1 rounded"
									/>
									<up-device-alert-wrap
										:activator-props="{
											class: 'ma-1',
										}"
										:readonly="readonly"
										v-if="d.__device"
										:up-list="data.up_list"
										:device-id="parseInt(d.__device.device.id)"
										@reload="reload"
									/>
								</td>
								<td>
									<div v-if="d.__device?.device_data">
										{{ parseFloat(d.__device.device_data.value_old).toFixed(d.__device.decimals) }}
									</div>
								</td>
								<td>
									<v-text-field
										label="Памет"
										v-if="d.__device?.device?.device_N"
										v-model="data.otcheti[d.__device.device.device_N].value_mem"
										:disabled="readonly"
										dense hide-details outlined style="width: 120px;"
										class="my-2"
										@focus="$event.target.select()"
										inputmode="decimal"
									/>
									<v-text-field
										label="Показание"
										v-if="d.__device?.device?.device_N"
										v-model="data.otcheti[d.__device.device.device_N].value"
										:disabled="readonly"
										dense hide-details outlined style="width: 120px;"
										class="my-2"
										@focus="$event.target.select()"
										inputmode="decimal"
									/>
								</td>
								<td v-if="showDevicePopupBtn" class="text-right">
									<device-popup
										:data.sync="data.values_device[index]"
										:device="d.__device?.device"
										:readonly="readonly || !isMontajnik"
										:action="service?.action"
									>
										<template #activator="{attrs, on}">
											<v-btn icon :color="isMissingDataDevice(value) ? 'warning' : 'success'" v-bind="attrs" v-on="on" :disabled="!value.service_id || getBoolean(value.is_declined)">
												<v-icon>mdi-pencil-circle</v-icon>
											</v-btn>
										</template>
									</device-popup>
								</td>
							</tr>
						</template>
					</service-price-sync>
					</tbody>
				</v-simple-table>
				<v-divider/>
				<v-toolbar dense flat rounded :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'" class="mt-2">Услуги за имота</v-toolbar>
				<v-simple-table dense>
					<thead>
					<tr>
						<th>Услуга</th>
						<th>Стойност</th>
						<th>Цена</th>
						<th nowrap>ТО %</th>
						<th width="1%"></th>
						<th width="1%" v-if="!isMontajnik">Оригинална стойност</th>
						<th width="99%"></th>
					</tr>
					</thead>
					<tbody>
					<tr v-for="(item, index) in data.values_imot" :key="index">
						<td nowrap>{{ getServiceTitle(item.service_id) }}</td>
						<td>{{ floatSumFixed(item.price_service_original, item.price_product_original).toFixed(2) }}</td>
						<td>
							<v-text-field outlined dense hide-details
								:disabled="!data.values_imot[index].enabled || readonly"
								v-model="data.values_imot[index].price"
								style="width: 100px"
								class="my-2"
								@focus="$event.target.select()"
								inputmode="decimal"
							/>
						</td>
						<td nowrap>{{ data.values_imot[index].enabled ? calcDiscountPercent(floatSumFixed(item.price_service_original, item.price_product_original), data.values_imot[index].price, 2) : '' }}</td>
						<td>
							<v-simple-checkbox
								v-model="data.values_imot[index].enabled"
								:disabled="readonly"
								@input="onServiceEnable(index, $event)"
							/>
						</td>
						<td v-if="!isMontajnik">{{item.price_original}}</td>
						<td></td>
					</tr>
					</tbody>
				</v-simple-table>

				<v-alert dense text color="primary" class="mt-2">
					ОБЩО СУМА: <strong>{{ totalAmount.toFixed(2) }}</strong>
					<base-input
						v-if="showPaymentType && !readonly"
						type="btn-toggle"
						mandatory
						:value="paymentType"
						@input="$emit('update:paymentType', $event)"
						:options="JobRequestPaymentTypeLabels"
						dense class="ml-4"
						color="primary"
					/>
					<v-chip v-if="showPaymentType && readonly && paymentType" class="ml-4" label color="primary">
						{{JobRequestPaymentTypeLabels[paymentType]}}
					</v-chip>
				</v-alert>

			</div>
		</template>
	</data-loader>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import DataLoader from "@/ittijs/components/DataLoader.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {JobServicesModel} from "@/views/montaji/job_requests/Model";
import {
	DeviceTypeLabels,
	DeviceTypeToMontajServiceType, JobRequestPaymentTypeLabels,
} from "@/ittijs/Nomenclatures";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import ServiceSelectBase from "@/views/montaji/services/ServiceSelectBase.vue";
import ServicePriceSync from "@/views/frontdesk/ServicePriceSync.vue";
import {calcDiscountPercent, floatSum} from "@/ittijs/utils";
import UpDeviceAlertWrap from "@/ittijs/components/UvedomitelniPisma/UpDeviceAlertWrap.vue";
import DevicePopup from "@/views/montaji/montajnik_ui/DevicePopup.vue";
import {cloneDeep} from "lodash";
import ServiceDecline from "@/views/montaji/montajnik_ui/ServiceDecline.vue";
import BaseInput from "@/ittijs/Inputs/BaseInput.vue";

const model = ITTIModelProvider.getModel(JobServicesModel);

export default {
	components: {
		BaseInput,
		ServiceDecline,
		DevicePopup,
		UpDeviceAlertWrap,
		ServicePriceSync,
		ServiceSelectBase,
		DateFormat,
		DataLoader,
	},
	props: {
		imot: {},
		jobId: {}, // could be null
		isMontajnik: Boolean,
		readonly: Boolean,
		showPaymentType: Boolean,
		paymentType: {}, // for syncing
		isMissingData: {}, // for syncing
	},
	data() {
		return {
			model,
			data: null,
		}
	},
	computed: {
		JobRequestPaymentTypeLabels() {
			return Object.fromEntries(JobRequestPaymentTypeLabels);
		},
		DeviceTypeLabels: () => DeviceTypeLabels,
		DeviceTypeToMontajServiceType: () => DeviceTypeToMontajServiceType,
		totalAmount(){
			const valDevices = this.data && this.data.values_device || [];
			const valImot    = this.data && this.data.values_imot || [];
			return floatSum(
				...valDevices
					.filter(item => item.is_declined !== true && item.is_declined !== '1')
					.map(item => item.price),
				...valImot.map(item => item.price),
			);
		},
		valuesFiltered(){
			let values_device = this.data && this.data.values_device || [];
			const values_imot = this.data && this.data.values_imot || [];
			const otcheti = this.data && this.data.otcheti || {};
			values_device = values_device.map(e => {
				e = cloneDeep(e);
				delete e.__device;
				return e;
			});
			return {
				values_device,
				values_imot,
				otcheti,
			};
		},
		jobStatus(){
			return this.data?.job?.status;
		},
		showDevicePopupBtn(){
			return this.isMontajnik || this.jobStatus === 'completed';
		},
		isMissingDataInner(){
			return this.data?.values_device?.some(item => this.isMissingDataDevice(item)) ?? false;
		},
	},
	watch: {
		"data.values_device": {
			deep: true,
			handler() {
				this.$emit('input', this.valuesFiltered);
			}
		},
		"data.values_imot": {
			deep: true,
			handler() {
				this.$emit('input', this.valuesFiltered);
			}
		},
		"data.otcheti": {
			deep: true,
			handler() {
				this.$emit('input', this.valuesFiltered);
			}
		},
		isMissingDataInner: {
			immediate: true,
			handler(val){
				this.$emit('update:isMissingData', val);
			},
		},
	},
	methods: {
		isMissingDataDevice(device){
			if (this.getBoolean(device.is_declined)) return false;
			if (!device.service_id) return false;
			const service = this.data.services.find(service => service.id == device.service_id);
			if (service) {
				switch (service.action) {
					case 'insert':
					case 'replace':
						return (device.dev_new__device_N ?? '') == '';
				}
			}
			return false;
		},
		getBoolean(val){
			if (typeof val === "boolean") return val;
			return val === '1' || val === 1;
		},
		floatSumFixed: floatSum,
		calcDiscountPercent,
		onServiceEnable(index, checked){
			if (checked) {
				this.data.values_imot[index].price = floatSum(
					this.data.values_imot[index].price_service_original,
					this.data.values_imot[index].price_product_original
				).toFixed(2);
			}
			else {
				this.data.values_imot[index].price = null;
			}
		},
		getServiceTitle(serviceId){
			try {
				return this.data.services.find(item => item.id == serviceId).title;
			}
			catch (e) {
				return '';
			}
		},
		addDevice(type){
			if (this.data && this.data.values_device) {
				this.data.values_device.push({
					__device: null,
					id: null,
					service_id: null,
					device_id: null,
					price_service_original: null,
					price_product_original: null,
					price: null,
					dev_new__device_type_code: type,
				});
			}
		},
		reload(){
			return this.$refs.loader.loadDebounce();
		},
	},
}
</script>