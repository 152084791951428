<template>
	<div>
		<template v-for="item in types">
			<span
				v-if="item.forceOn || countsFiltered[item.type]"
				:key="item.type"
				:title="DeviceTypeLabels.get(item.type)"
				class="pr-2"
			>
				<span :class="item.class" class="pr-1 font-weight-bold">{{ item.label }}:</span>
				<span :class="countsFiltered[item.type] ? item.class : 'grey--text'">{{ countsFiltered[item.type] }}</span>
			</span>
		</template>
	</div>
</template>

<script>
import {DeviceType, DeviceTypeLabels} from "@/ittijs/Nomenclatures";

const types = [
	{type: DeviceType.RAZPREDELITEL, label: 'Р',  class: 'red--text text--darken-2', forceOn: true, }, // плюс еталон!
	{type: DeviceType.TOPLOMER,      label: 'Тп', class: 'red--text text--darken-2', forceOn: true, },
	{type: DeviceType.VODOMER_HOT,   label: 'В',  class: 'red--text text--darken-2', forceOn: true, },
	{type: DeviceType.VODOMER_COLD,  label: 'В',  class: 'blue--text',         forceOn: false, },
	{type: DeviceType.STUDOMER,      label: 'Ст', class: 'blue--text',         forceOn: false, },
];

const getNum = num => parseInt(num) || 0;

export default {
	props: {
		counts: Object,
	},
	computed: {
		DeviceTypeLabels: () => DeviceTypeLabels,
		types: () => types,
		countsFiltered(){
			return {
				[DeviceType.RAZPREDELITEL] : getNum(this.counts[DeviceType.RAZPREDELITEL]) + getNum(this.counts[DeviceType.ETALON]),
				[DeviceType.TOPLOMER]      : getNum(this.counts[DeviceType.TOPLOMER]),
				[DeviceType.VODOMER_HOT]   : getNum(this.counts[DeviceType.VODOMER_HOT]),
				[DeviceType.VODOMER_COLD]  : getNum(this.counts[DeviceType.VODOMER_COLD]),
				[DeviceType.STUDOMER]      : getNum(this.counts[DeviceType.STUDOMER]),
			}
		},
	},
}
</script>