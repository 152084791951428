<template>
	<v-btn
		v-bind="$attrs"
		v-on="$listeners"
		:color="color"
		@click="onClick"
		:loading="loading"
	>
		<slot>{{label}}</slot>
	</v-btn>
</template>

<script>
/**
 * Сваляне на файл през DownloadCache
 */
export default {
	props: {
		color: {
			default: 'primary',
		},
		label: {
			type: String,
			default: 'Свали',
		},
		restService: Function,
	},
	data: ()=>({
		loading: false,
	}),
	methods: {
		async onClick(){
			if (!this.loading) {
				this.loading = true;
				try {
					const url = await this.restService();
					window.open(url, '_blank');
				}
				finally {
					this.loading = false;
				}
			}
		},
	},
}
</script>