<template>
	<v-dialog v-model="open" v-bind="$attrs" v-on="$listeners" :content-class="classes">

		<template #activator="scope">
			<slot name="activator" v-bind="scope" />
		</template>

		<v-sheet v-if="open" :class="{'position-relative': this.relative}">

			<v-toolbar flat dense v-if="!hideTitle">
				<v-toolbar-title class="text-h5 font-weight-light">{{title}}</v-toolbar-title>
				<v-spacer />
				<v-btn
					v-if="!hideClose"
					@click="open = false"
					text small elevation="0" min-width="0" width="28" class="mr-n2">
					<v-icon>mdi-window-close</v-icon>
				</v-btn>
			</v-toolbar>

			<v-divider v-if="!hideTitle"/>
			<div :style="{maxHeight:computedMaxContentHeight,overflowX:'auto'}">
				<slot v-bind="{state: open, open: function(){ open = true }, close: function(){ open = false }}" />
			</div>

			<v-divider v-if="$scopedSlots.actions" />
			<v-card-actions v-if="$scopedSlots.actions">
				<slot name="actions" v-bind="{state: open, open: function(){ open = true }, close: function(){ open = false }}" />
			</v-card-actions>

		</v-sheet>

	</v-dialog>
</template>

<style scoped lang="sass">
:deep(.v-dialog.v-dialog__top)
	align-self: start
</style>

<script>
export default {
	props: {
		title: String,
		hideTitle: Boolean,
		value: {},
		hideClose: Boolean,
		maxContentHeight: {
			type: String,
		},
		top: Boolean,
		relative: Boolean,
	},
	data(){
		return {
			open: this.value,
		}
	},
	computed: {
		classes(){
			return [
				...(this.top ? ['v-dialog__top'] : []),
			].join(' ');
		},
		computedMaxContentHeight(){
			if (this.maxContentHeight) return this.maxContentHeight;
			// височината на v-dialog е закована на макс. 90%
			// ~50px е заглавието, още 70px резервираме за "actions"
			if (this.$scopedSlots.actions) return 'calc(90vh - 120px)';
			return 'calc(90vh - 50px)';
		},
	},
	watch: {
		value(val){
			this.open = val;
		},
		open(val){
			this.$emit('input', val);
			if (val) this.$emit('open');
			else this.$emit('close');
		},
	},
	methods: {
		doOpen(){
			this.open = true;
		},
		doClose(){
			this.open = false;
		},
	},
}
</script>