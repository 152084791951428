import IModelMixin from "@/ittijs/IModelMixin";

export default {
	mixins: [IModelMixin],
	props: {
		fields: [Array,String],
		disabledFields: [Array,String],
		data: {
			default: () => ({}),
		},
		errors: {
			default: () => ({}),
		},
	},
	data() {
		return {
			lazyData: this.data || {},
		}
	},
	computed: {
		getFields(){
			let fields = [];
			if (typeof this.fields === 'string') {
				fields = this.fields.split(',');
			}
			else if (Array.isArray(this.fields) && this.fields.length) {
				fields = this.fields;
			}
			return fields
				.map(f => this.imodel.fields[f])
				.filter(f => !!f);
		},
		getDisabledFields(){
			let fields = [];
			if (typeof this.disabledFields === 'string') {
				return this.disabledFields.split(',');
			}
			else if (Array.isArray(this.disabledFields) && this.disabledFields.length) {
				fields = this.disabledFields;
			}
			return fields;
		},
		slotScope() {
			return {
				data: this.lazyData,
				errors: this.errors,
				imodel: this.imodel,
			};
		},
	},
	watch: {
		data(val) {
			this.lazyData = val;
		},
		lazyData(val){
			this.$emit('update:data', val);
		},
	},
	methods: {
		isDisabled(field) {
			return this.getDisabledFields.some(f => f === field);
		},
	},
};
