<template>

	<v-container>

		<!-- @slot Before the first field row -->
		<slot name="before-fields" v-bind="slotScope" />

		<v-row v-if="$scopedSlots['before-fields-right']" no-gutters justify="center">
			<v-col class="pt-3" :style="leftColStyle"></v-col>
			<v-col class="pa-1" :style="rightColStyle">
				<!-- @slot Before the first field row, in the right col (lined up with the other fields) -->
				<slot name="before-fields-right" v-bind="slotScope" />
			</v-col>
		</v-row>

		<template v-for="f in getFields">

			<!-- @slot Put a new row directly above the given one -->
			<slot :name="'before-row.'+f.name" v-bind="getFieldScope(f)" />

			<v-row v-if="$scopedSlots['before-row-right.'+f.name]" :key="'before-row-right.'+f.name" no-gutters justify="center">
				<v-col class="pt-3" :style="leftColStyle"></v-col>
				<v-col class="pa-1" :style="rightColStyle">
					<!-- @slot Put a new field directly above the given one, in the right col -->
					<slot :name="'before-row-right.'+f.name" v-bind="getFieldScope(f)" />
				</v-col>
			</v-row>

			<v-row :key="'replace-row.'+f.name" no-gutters justify="center">
				<!-- @slot Replace field row -->
				<slot :name="'row.'+f.name" v-bind="getFieldScope(f)">
					<v-col class="pt-3" :style="leftColStyle">
						<!-- @slot Replace field label -->
						<slot v-if="moveLabelLeft(f)" :name="'label.'+f.name" v-bind="getFieldScope(f)">
							<template v-if="f.description">
								<v-tooltip top>
									<template v-slot:activator="{ on, attrs }">
										<div v-bind="attrs" v-on="on">{{ f.label }} <v-icon color="orange">mdi-information-outline</v-icon></div>
									</template>
									{{ f.description }}
								</v-tooltip>
							</template>
							<template v-else>
								{{ f.label }}
							</template>
						</slot>
					</v-col>
					<v-col class="pa-1" :style="rightColStyle">
						<!-- @slot Replace field input -->
						<slot :name="'field.'+f.name" v-bind="getFieldScope(f)">
							<edit-input
								:label="moveLabelLeft(f) ? '' : f.label"
								outlined dense
								:name="f.name"
								:data="data"
								:errors="errors"
								:disabled="isDisabled(f.name)"
							></edit-input>
						</slot>
					</v-col>
				</slot>
			</v-row>

			<v-row v-if="$scopedSlots['after-row-right.'+f.name]" :key="'after-row-right.'+f.name" no-gutters justify="center">
				<v-col class="pt-3" :style="leftColStyle"></v-col>
				<v-col class="pa-1" :style="rightColStyle">
					<!-- @slot Put a new field directly below the given one, in the right col -->
					<slot :name="'after-row-right.'+f.name" v-bind="getFieldScope(f)" />
				</v-col>
			</v-row>

			<!-- @slot Put a new row directly below the given one -->
			<slot :name="'after-row.'+f.name" v-bind="getFieldScope(f)"/>

		</template>

		<v-row v-if="$scopedSlots['after-fields-right']" no-gutters justify="center">
			<v-col class="pt-3" :style="leftColStyle"></v-col>
			<v-col class="pa-1" :style="rightColStyle">
				<!-- @slot After the last field row, in the right col (lined up with the other fields) -->
				<slot name="after-fields-right" v-bind="slotScope" />
			</v-col>
		</v-row>

		<!-- @slot After the last field row -->
		<slot name="after-fields" v-bind="slotScope" />

	</v-container>

</template>

<script>
import Mixin from "./Mixin";
import EditInput from "@/ittijs/Inputs/EditInput.vue";

export default {
	name: 'ModelFormTplSideBySide',

	components: {EditInput},
	mixins: [Mixin],

	props: {
		leftColWidth: {
			type: Number,
			default: 250,
		},
		rightColWidth: {
			type: Number,
			default: null,
		},
		leftColAlign: {
			type: String,
			default: 'left',
		},
	},

	computed: {
		leftColStyle(){
			return `flex: 0 1 ${this.leftColWidth}px; text-align: ${this.leftColAlign};`;
		},
		rightColStyle(){
			if (this.rightColWidth === null) return '';
			return `flex: 0 1 ${this.rightColWidth}px;`;
		},
	},

	methods: {
		moveLabelLeft(field) {
			return field.inputType !== 'checkbox';
		},
		getFieldScope(field) {
			return {
				...this.slotScope,
				field: field,
				disabled: this.isDisabled(field.name),
				leftColStyle: this.leftColStyle,
				rightColStyle: this.rightColStyle,
			}
		},
	},

}
</script>