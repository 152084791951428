/**
 * registerable mixin for form elements
 * provides method regInput(group, input)
 */
export default {
  data: ()=>({
    inputGroups: {},
  }),
  provide: function(){
    return {
      inputGroups: {
        regInput: this.regInput,
        unregInput: this.unregInput,
        getInputGroup: this.getInputGroup,
      },
    };
  },
  methods: {
    regInput(group, input) {
      if (typeof this.inputGroups[group] === 'undefined') {
        this.inputGroups[group] = [];
      }
      this.inputGroups[group].push(input);
    },
    unregInput(group, input) {
      const grp = this.inputGroups[group];
      if (Array.isArray(grp)) {
        const found = grp.find(i => i._uid === input._uid)
        if (!found) return
        this.inputGroups[group] = this.inputGroups[group].filter(i => i._uid !== found._uid)
      }
    },
    getInputGroup(group) {
      return this.inputGroups[group] || [];
    },
  }
}