<template>
	<edit-input
		ref="input"
		v-bind="$attrs" v-on="$listeners"
		:options="items"
		:disabled="disabled"
	/>
</template>

<script>
import EditInput from "@/ittijs/Inputs/EditInput";
import {itemsForSelect} from "@/ittijs/utils";
import IModelMixin from "@/ittijs/IModelMixin";
export default {
	mixins: [IModelMixin],
	components: {EditInput},
	props: {
		insert: Boolean,
	},
	computed: {
		field(){
			return this.imodel && this.$attrs.name && this.imodel.fields[ this.$attrs.name ] || null;
		},
		radiators(){
			return this.field.typesRadiator || [];
		},
		vodomers(){
			return this.field.typesVoda || [];
		},
		value(){
			return this.$attrs.data && this.$attrs.data[ this.field['name'] ] || null;
		},
		isValRadiator(){
			return this.radiators.indexOf(this.value) > -1;
		},
		isValVodomer(){
			return this.vodomers.indexOf(this.value) > -1;
		},
		items(){
			let options = this.field['options'] && itemsForSelect(this.field.options) || [];
			if (!this.insert && this.isValRadiator) {
				options = options.filter(item => this.radiators.indexOf(item.value) > -1);
			}
			if (!this.insert && this.isValVodomer) {
				options = options.filter(item => this.vodomers.indexOf(item.value) > -1);
			}
			return options;
		},
		disabled(){
			return this.$attrs.disabled || !this.isValRadiator && !this.isValVodomer && !this.insert;
		},
	},
}
</script>