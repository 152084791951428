<!--
Display user info by ID
-->

<template>
	<span><slot v-if="user" v-bind:user="user">{{user.user}}</slot></span>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import Model from '@/views/users/Model';

const model = new Model;
let cachedData;

export default {
	props: {
		id: Number,
	},
	data() {
		return {
			users: null,
		}
	},
	computed: {
		user(){
			const user = this.users && this.users.find(user => user.id == this.id) || null;
			if (!user) return null;
			return {
				id: parseInt(user.id),
				user: user.user,
				is_active: user.is_active === '1',
				group_id: parseInt(user.group_id),
				group_name: user.group_name,
			}
		},
	},
	async created() {
		if (!cachedData) {
			cachedData = await model.getInfoList();
		}
		this.users = cachedData;
	},
}
</script>