<template>
	<v-dialog v-model="isOpen" width="700">
		<template v-if="!noActivator" v-slot:activator="{ on, attrs }">
			<slot name="activator" v-bind="{ on, attrs }">
				<v-btn right icon small v-bind="attrs" v-on="on">
					<v-icon dense color="primary">mdi-pencil-circle</v-icon>
				</v-btn>
			</slot>
		</template>
		<template v-slot:default="">
			<v-card>
				<edit-column
					max-content-height="calc(90vh - 120px)"
					v-if="isOpen"
					:title="title || 'УП - редакция'"
					:id="id.toString()"
					:data.sync="data"
					:data-append="{devices:{fixed:isFixed,processed:isProcessed}}"
					:fields-callback="fields"
					disabled-fields="file"
					@loaded="onEditFormUpdate"
					@close="isOpen = false"
					@saveclose="isOpen = false; $emit('reload')"
					ref="EditForm"
					:imodel="model"
					:edit-button-options="{showDelete:false}"
					:left-col-width="250"
				>

					<template #field.created_time="{row}">
						<div class="mt-2">
							<date-time-format :datetime="row.created_time" />
						</div>
					</template>

					<template #after-row.expiration_days="{}">
						<v-row no-gutters>
							<v-col class="pt-2" style="flex: 0 1 250px">Влизане в сила</v-col>
							<v-col class="px-1">
								<date-format :date="expirationDate" class="pa-1 rounded my-1" past-class="red white--text" />
							</v-col>
						</v-row>
					</template>

					<template #after-fields="{row}">
						<v-divider/>
						<v-simple-table dense>
							<thead>
							<tr>
								<th>Уред</th>
								<th>Причина</th>
								<th>
									<icon-tooltip top>
										<template #activator="{attrs,on}">
											<span v-bind="attrs" v-on="on">
												Обработено
												<v-icon>mdi-comment-question</v-icon>
											</span>
										</template>
										Предприети са действията при неизпълнение на УП-то
									</icon-tooltip>
								</th>
								<th>
									<icon-tooltip top>
										<template #activator="{attrs,on}">
											<span v-bind="attrs" v-on="on">
												Приключено
												<v-icon>mdi-comment-question</v-icon>
											</span>
										</template>
										Отстранена е причината за УП-то
									</icon-tooltip>
								</th>
							</tr>
							</thead>
							<tbody>
							<tr v-for="(item, index) in row.devices" :key="index">
								<td>{{ item.device_N }}</td>
								<td>{{ item.reason }}</td>
								<td>
									<div class="d-flex">
										<v-simple-checkbox
											:disabled="!authorization.hasRole(RolesEnum.UVEDOMITELNI_PISMA_FIXED)"
											v-model="isProcessed[item.id]"
										/>
										<v-menu left offset-x :close-on-content-click="false" v-if="item.is_processed">
											<template #activator="{ on }">
												<v-btn small icon v-on="on">
													<v-icon>mdi-information-outline</v-icon>
												</v-btn>
											</template>
											<v-simple-table dense>
												<tbody>
												<tr>
													<td>Потребител:</td>
													<td><user-info :id="parseInt(item.processed_by)" /></td>
												</tr>
												<tr>
													<td>Дата/час:</td>
													<td><date-time-format :datetime="item.processed_time" /></td>
												</tr>
												</tbody>
											</v-simple-table>
										</v-menu>
									</div>
								</td>
								<td>
									<div class="d-flex">
										<v-simple-checkbox
											:disabled="!authorization.hasRole(RolesEnum.UVEDOMITELNI_PISMA_FIXED)"
											v-model="isFixed[item.id]"
										/>
										<v-menu left offset-x :close-on-content-click="false" v-if="item.is_fixed">
											<template #activator="{ on }">
												<v-btn small icon v-on="on">
													<v-icon>mdi-information-outline</v-icon>
												</v-btn>
											</template>
											<v-simple-table dense>
												<tbody>
												<tr>
													<td>Потребител:</td>
													<td><user-info :id="parseInt(item.fixed_by)" /></td>
												</tr>
												<tr>
													<td>Дата/час:</td>
													<td><date-time-format :datetime="item.fixed_time" /></td>
												</tr>
												</tbody>
											</v-simple-table>
										</v-menu>
									</div>
								</td>
							</tr>
							</tbody>
						</v-simple-table>
					</template>

				</edit-column>
			</v-card>
		</template>
	</v-dialog>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import Model from './Model'
import EditColumn from "@/ittijs/EditColumn";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import DateTimeFormat from "@/ittijs/components/DateTimeFormat.vue";
import {isoToDate} from "@/ittijs/utils";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import {RolesEnum} from "@/ittijs/Authorization";
import UserInfo from "@/ittijs/components/UserInfo.vue";
import IconTooltip from "@/ittijs/components/icon-tooltip.vue";

const model = new Model

export default {
	mixins: [SelectedUtilityMixin],
	components: {
		IconTooltip,
		UserInfo,
		DateFormat,
		DateTimeFormat,
		EditColumn,
	},
	inject: ['authorization'],
	props: {
		title: String,
		id: {},
		noActivator: Boolean,
	},
	data(){
		return {
			isOpen: false,
			model,
			data: null,
			isFixed: {},
			isProcessed: {},
		}
	},
	computed: {
		RolesEnum() {
			return RolesEnum
		},
		expirationDate(){
			if (!this.data) return null;
			if (!this.data.created_time) return null;
			if (!this.data.expiration_days) return null;
			const date = isoToDate(this.data.created_time);
			date.setHours(0, 0, 0, 0);
			date.setDate(date.getDate() + parseInt(this.data.expiration_days));
			return date;
		},
	},
	methods: {
		fields(){
			return [
				'up_type',
				'created_time',
				'received',
				'expiration_days',
				'file',
				'file_receipt',
				'delivery_type',
			].filter(f=>!!f);
		},
		onEditFormUpdate(){
			// fixed
			const isFixed = {};
			for (const d of this.data.devices) {
				isFixed[d.id] = d.is_fixed;
			}
			this.$set(this, 'isFixed', isFixed);
			// processed
			const isProcessed = {};
			for (const d of this.data.devices) {
				isProcessed[d.id] = d.is_processed;
			}
			this.$set(this, 'isProcessed', isProcessed);
		},
		open(){
			this.isOpen = true;
		},
	},
}
</script>