import ITTIModel from '@/ittijs/ITTIModel';

export default class extends ITTIModel {
  constructor() {
    super('JobRequests');
  }
  getScheduleData(utility, imot, start, end) {
    return this.fetch('getScheduleData', {utility, imot, start, end});
  }
  getImotInfo(utility, imot) {
    return this.fetch('getImotInfo', {utility, imot});
  }
  getScheduleDate(utility, scheduleId){
    return this.fetch('getScheduleDate', {utility, scheduleId});
  }
}

export class JobServicesModel extends ITTIModel {
  constructor() {
    super('JobServices');
  }
  getEditData(imotId, jobId) {
    return this.fetch('getEditData', {imotId, jobId});
  }
}
