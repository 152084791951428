<!--
Generic component
Watches [watch] property and executes debounced [callback] when it changes.
Supports scoped default slot, with [data], [loading], and [reload] items.
Fires [ready] event whenever data is loaded.
Also supports synced [data] and [loading] props.
Alternatively, if a [loading] slot is provided, it will be shown while loading.
Note: [watch] can now be an object literal - we use isEqual() to compare the contents, so the watcher won't be
triggered on every render.

@slot loading
@slot default
-->

<script>
import {isEqual} from "lodash";
import {debounceAsync} from "@/ittijs/utils";

export default {
	props: {
		watch: {},
		callback: Function,
		debounceTimeout: {
			type: Number,
			default: 10,
		},
		data: {}, // not used, only for sync
		loading: {}, // not used, only for sync
	},
	data(){
		return {
			dataInner: null,
			/**
			 * if we don't start as "loading", there's a moment where dataInner is null,
			 * but the state is considered "loaded"
			 */
			loadingInner: true,
			loadDebounce: debounceAsync(this.load, this.debounceTimeout),
			watchPrev: null,
		}
	},
	watch: {
		watch: {
			immediate: true,
			handler(val){
				// the old value of an object is not available, use watchPrev
				if (!isEqual(val, this.watchPrev)) {
					this.watchPrev = val;
					this.dataInner = null;
					this.$emit('update:data', this.dataInner);
					this.loadDebounce();
				}
			},
		},
		loadingInner: {
			immediate: true,
			handler(val){
				this.$emit('update:loading', val);
			},
		},
	},
	methods: {
		async load(){
			this.dataInner = null;
			this.$emit('update:data', this.dataInner);
			this.loadingInner = true;
			try {
				this.dataInner = await this.callback();
				this.$emit('update:data', this.dataInner);
				this.$emit('ready', this.dataInner);
			}
			catch (err) {
				this.$emit('error', err);
			}
			finally {
				this.loadingInner = false;
			}
		},
		reload(){
			this.loadDebounce();
		}
	},
	/**
	 * Renderless component which only returns the scoped slot "default".
	 * Alternatively, if a "loading" slot is provided, it will be shown while loading.
	 */
	render(){
		if (this.$scopedSlots.loading && this.loadingInner) {
			return this.$scopedSlots.loading({});
		}
		return this.$scopedSlots.default ? this.$scopedSlots.default({
			data: this.dataInner,
			loading: this.loadingInner,
			reload: () => this.loadDebounce(),
		}) : null;
	},
}
</script>