<template>
	<v-form v-model="valid" ref="form">
		<v-toolbar flat dense v-if="!hideTitle">
			<v-toolbar-title>{{ title || imodel.Title}}</v-toolbar-title>
			<v-spacer v-if="editButtonsTop" />
			<v-progress-circular v-if="editButtonsTop && loading" indeterminate class="mr-2" color="primary" />
			<edit-buttons
				v-if="editButtonsTop"
				v-bind="editButtonOptions"
				@save="saveNoClose?doSave():doSaveClose()"
				@delete="onDelete"
				@close="doClose"
				:showDelete="(idInternal > 0 || params) && (editButtonOptions.showDelete !== false)"
				:disabled="loading"
			/>
		</v-toolbar>

		<v-divider v-if="!hideTitle" />

		<div v-if="$scopedSlots.default" :style="{maxHeight:maxContentHeight,overflowX:'auto'}">
			<!-- @slot Replace everything -->
			<slot v-bind="slotScope"></slot>
		</div>

		<v-container
			v-if="!$scopedSlots.default"
			:style="{maxHeight:maxContentHeight,overflowX:'auto'}"
			@keydown.enter.prevent.stop="saveNoClose?doSave():doSaveClose()"
		>
			<!-- @slot Before the first field row -->
			<slot name="before-fields" v-bind="slotScope" />

			<template v-for="f in getEditFields">
				<v-row v-if="$scopedSlots['before-row-right.'+f.name]" :key="'before-row-right.'+f.name" no-gutters justify="center">
					<v-col class="pt-3" :style="leftColStyle"></v-col>
					<v-col class="pa-1" :style="rightColStyle">
						<!-- @slot Put a new field directly above the given one, in the right col -->
						<slot :name="'before-row-right.'+f.name" v-bind="slotScope" />
					</v-col>
				</v-row>
				<!-- @slot Put a new row directly above the given one -->
				<slot
					:name="'before-row.'+f.name"
					v-bind="{
						...slotScope,
						disabled:readOnly||isConstrained(f.name)||isDisabled(f.name),
						leftColStyle,
						rightColStyle,
					}"
				/>
				<v-row :key="'replace-row.'+f.name" no-gutters justify="center">
					<!-- @slot Replace field row -->
					<slot
						:name="'row.'+f.name"
						v-bind="{
							...slotScope,
							disabled:readOnly||isConstrained(f.name)||isDisabled(f.name),
							leftColStyle,
							rightColStyle,
						}"
					>
						<v-col class="pt-3" :style="leftColStyle">
							<!-- @slot Replace field label -->
							<slot
								v-if="moveLabelLeft(f)"
								:name="'label.'+f.name"
								v-bind="{...slotScope,disabled:readOnly||isConstrained(f.name)||isDisabled(f.name)}"
							>
								<template v-if="f.description">
									<v-tooltip top>
										<template v-slot:activator="{ on, attrs }">
											<div v-bind="attrs" v-on="on">{{ f.label }} <v-icon color="orange">mdi-information-outline</v-icon></div>
										</template>
										{{ f.description }}
									</v-tooltip>
								</template>
								<template v-else>
									{{ f.label }}
								</template>
							</slot>
						</v-col>
						<v-col class="pa-1" :style="rightColStyle">
							<!-- @slot Replace field input -->
							<slot
								:name="'field.'+f.name"
								v-bind="{...slotScope,field:f,disabled:readOnly||isConstrained(f.name)||isDisabled(f.name)}"
							>
								<edit-input
									:label="moveLabelLeft(f) ? '' : f.label"
									outlined dense
									:name="f.name"
									:data="row"
									:errors="err"
									:disabled="readOnly||isConstrained(f.name)||isDisabled(f.name)"
								></edit-input>
							</slot>
						</v-col>
						<v-col v-if="thirdCol" v-bind="thirdColAttrs">
							<!-- @slot Put something in a 3rd column next to the field -->
							<slot :name="'third.'+f.name" v-bind="{...slotScope,field:f,disabled:readOnly||isConstrained(f.name)||isDisabled(f.name)}" />
						</v-col>
					</slot>
				</v-row>
				<v-row v-if="$scopedSlots['after-row-right.'+f.name]" :key="'after-row-right.'+f.name" no-gutters justify="center">
					<v-col class="pt-3" :style="leftColStyle"></v-col>
					<v-col class="pa-1" :style="rightColStyle">
						<!-- @slot Put a new field directly below the given one, in the right col -->
						<slot :name="'after-row-right.'+f.name" v-bind="slotScope" />
					</v-col>
				</v-row>
				<!-- @slot Put a new row directly below the given one -->
				<slot
					:name="'after-row.'+f.name"
					v-bind="{
						...slotScope,
						disabled:readOnly||isConstrained(f.name)||isDisabled(f.name),
						leftColStyle,
						rightColStyle,
					}"
				/>
			</template>

			<v-row v-if="$scopedSlots['after-fields-right']" no-gutters justify="center">
				<v-col class="pt-3" :style="leftColStyle"></v-col>
				<v-col class="pa-1" :style="rightColStyle">
					<!-- @slot After the last field row, in the right col (lined up with the other fields) -->
					<slot name="after-fields-right" v-bind="slotScope" />
				</v-col>
			</v-row>
			<!-- @slot After the last field row -->
			<slot
				name="after-fields"
				v-bind="{
					...slotScope,
					leftColStyle,
					rightColStyle,
				}"
			/>
		</v-container>

		<v-divider v-if="editButtonsBottom" />

		<v-toolbar flat v-if="editButtonsBottom">
			<edit-buttons
				class="flex-grow-1"
				v-bind="editButtonOptions"
				@save="saveNoClose?doSave():doSaveClose()"
				@delete="onDelete"
				@close="doClose"
				:showDelete="(idInternal > 0 || params) && (editButtonOptions.showDelete !== false)"
				:disabled="loading"
			>
				<template #right-inside>
					<v-progress-circular v-if="loading" indeterminate class="mr-2" color="primary" />
				</template>
			</edit-buttons>
		</v-toolbar>

		<ConfirmDlg ref="deleteConfirm" label-confirm="Изтрий" color-confirm="red darken-1" icon-confirm="mdi-delete">
			Моля потвърдете изтриването на записа
		</ConfirmDlg>

		<v-snackbar
			color="red"
			v-model="snackbar.active"
			:timeout="-1"
			:multiLine="false"
		>
			<div v-for="(t,i) in snackbar.text" :key="i">{{ t }}</div>
			<template v-slot:action="{ attrs }">
				<v-btn
					text
					v-bind="attrs"
					@click="snackbar.active = false"
				>
					Затвори
				</v-btn>
			</template>
		</v-snackbar>

		<!-- @slot After everything, incl. edit buttons -->
		<slot name="after" v-bind="slotScope" />

	</v-form>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */

import EditMixin from '@/ittijs/EditMixin';
import FormMixin from '@/ittijs/FormMixin';

export default {

	mixins: [
		EditMixin,
		FormMixin,
	],

	props: {
		leftColWidth: {
			type: Number,
			default: 250,
		},
		rightColWidth: {
			type: Number,
			default: null,
		},
		thirdCol: Boolean,
		thirdColAttrs: Object,
		saveNoClose: Boolean,
	},

	computed: {
		leftColStyle(){
			return `flex: 0 1 ${this.leftColWidth}px`;
		},
		rightColStyle(){
			if (this.rightColWidth === null) return '';
			return `flex: 0 1 ${this.rightColWidth}px`;
		},
	},

	methods: {

		moveLabelLeft(field) {
			if (field.inputType === 'checkbox') return false;
			return true;
		},

	},

}
</script>