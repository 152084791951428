<template>
	<v-select
		dense outlined hide-details
		:value="value"
		@input="$emit('input', $event)"
		:items="ops"
		:label="label"
		:readonly="readonly"
		:clearable="clearable"
	/>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";
import {itemsForSelect} from "@/ittijs/utils";

const OpTranslations = {
	"is null"               : "е NULL",
	"is not null"           : "не е NULL",
	"equal"                 : "=",
	"less than"             : "<",
	"less or equal than"    : "≤",
	"greater than"          : ">",
	"greater or equal than" : "≥",
	"contains any of"       : "съдържа поне 1 от",
	"contains all of"       : "съдържа всяко от",
	"begins with"           : "започва с",
	"ends with"             : "завършва с",
	"contains"              : "съдържа",
	"is empty"              : "е празно",
};

export default {
	name: "FilterOp",
	mixins: [IModelMixin],
	props: {
		value: {},
		label: {
			type: String,
			default: "Операция",
		},
		readonly: {
			type: Boolean,
			default: false,
		},
		clearable: {
			type: Boolean,
			default: false,
		},
		rule: Object,
	},
	data(){
		return {
			operations: [],
		}
	},
	computed: {
		fieldObj(){
			return this.rule && this.rule.field && this.imodel.fields[ this.rule.field ] || null;
		},
		searchOps(){
			return this.fieldObj && this.fieldObj.searchOps || null;
		},
		ops() {
			const result = {};
			if (this.searchOps) {
				for (const op in this.searchOps) {
					switch (typeof this.searchOps[op]) {
						case 'string':
							result[op] = this.opTranslate(this.searchOps[op]);
							break;
						case 'object':
							result[op] = this.opTranslate(this.searchOps[op].label);
							break;
					}
				}
			}
			return itemsForSelect(result);
			// return this.opsForField(this.imodel.fields[this.rule.field]);
		},
	},
	methods: {
		opTranslate(op) {
			return OpTranslations[op] || op;
		},
	},
}
</script>