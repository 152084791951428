<template>
	<v-dialog v-if="list && list.length > 0" max-width="600">
		<template #activator="{attr,on}">
			<v-chip v-bind="{...attr,...activatorProps}" v-on="on" color="error" label small>
				<v-icon small>mdi-alert</v-icon>
				УП
			</v-chip>
		</template>
		<v-card>
			<v-simple-table dense style="overflow: hidden">
				<thead>
				<tr class="headings2">
					<td>Създадено</td>
					<td nowrap>Влиза в сила</td>
					<td>Причина</td>
					<td>Файл</td>
					<td v-if="!readonly"></td>
				</tr>
				</thead>
				<tbody>
				<tr v-for="(item, index) of list" :key="index">
					<td><date-time-format :datetime="item.created_time" /></td>
					<td><date-format :date="item.expires" past-class="red white--text pa-1 rounded my-1" /></td>
					<td>{{ item.reason }}</td>
					<td>
						<v-btn v-if="item.file" :href="item.file.url" target="_blank" small text>
							{{ item.file.name }}
						</v-btn>
					</td>
					<td v-if="!readonly">
						<UPEditPopup
							:id="item.id"
							@reload="$emit('reload')"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn right icon small v-bind="attrs" v-on="on" tabindex="-1">
									<v-icon color="primary" size="24">mdi-pencil-circle</v-icon>
								</v-btn>
							</template>
						</UPEditPopup>
					</td>
				</tr>
				</tbody>
			</v-simple-table>
		</v-card>
	</v-dialog>
</template>

<script>
import DateFormat from "@/ittijs/components/DateFormat.vue";
import DateTimeFormat from "@/ittijs/components/DateTimeFormat.vue";
import UPEditPopup from "@/ittijs/components/UvedomitelniPisma/UPEditPopup.vue";

export default {
	components: {UPEditPopup, DateTimeFormat, DateFormat},
	props: {
		list: Array,
		readonly: Boolean,
		activatorProps: {
			type: Object,
			default: () => ({}),
		},
	},
	methods: {
	},
}
</script>