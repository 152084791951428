import ITTIModel from "@/ittijs/ITTIModel";

export default class extends ITTIModel {
  constructor() {
    super('UvedomitelniPisma');
  }
  loadDataForImot(utility, imot){
    return this.fetch('loadDataForImot', {utility, imot});
  }
  saveDataForImot(utility, imot, devices, data){
    return this.fetch('saveDataForImot', {utility, imot}, {data, devices});
  }
}
