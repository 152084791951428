<template>
	<v-select
		dense outlined hide-details
		:value="value"
		@input="$emit('input', $event)"
		:items="fields"
		:label="label"
		:readonly="readonly"
	/>
</template>

<script>
import IModelMixin from "@/ittijs/IModelMixin";

export default {
	name: "FilterField",
	mixins: [IModelMixin],
	props: {
		value: {},
		label: {
			type: String,
			default: "Поле",
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		fields() {
			const fields = [];
			let heading = '';
			let f;
			for (const k in this.imodel.fields) {
				f = this.imodel.fields[k];
				if (f.groupTitle && f.groupTitle !== heading) {
					heading = f.groupTitle;
					fields.push({
						value: k,
						text: f.label,
						header: heading,
						divider: true,
					});
				}
				else {
					fields.push({
						value: k,
						text: f.label,
					});
				}
			}
			return fields;
		},
	},
}
</script>