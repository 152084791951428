<template>
	<div style="display: contents;">
		<quick-search-set :filter-group.sync="filterQuick">
			<v-sheet rounded elevation="2" class="ma-2 pa-1 d-flex">
				<quick-search class="ma-1" style="max-width: 200px;" field="created_date" :op="FilterOps.EQUALS" />
				<quick-search class="ma-1" style="max-width: 200px;" field="scheduled_date" :op="FilterOps.EQUALS" />
				<quick-search class="ma-1" style="max-width: 200px;" field="n_mg_jobs.status" :op="FilterOps.EQUALS" />
				<quick-search class="ma-1" style="max-width: 200px;" field="n_imot.imot_N" :op="FilterOps.EQUALS" />
				<quick-search class="ma-1" style="max-width: 200px;" field="n_mg_jobs.id" :op="FilterOps.EQUALS" label="ID" />
			</v-sheet>
			<v-sheet rounded elevation="2" class="ma-2 pa-1 d-flex">
				<quick-search class="ma-1" style="max-width: 200px;" field="n_mg_jobs.processing_assignment_user_id" :op="FilterOps.EQUALS" />

				<v-divider vertical class="mx-2" />
				<field-label :imodel="imodel" name="sql_is_processed_montaji" class="py-3" />
				<quick-search class="ma-1" style="max-width: 200px;" field="sql_is_processed_montaji" :op="FilterOps.EQUALS" input-type="btn-toggle" />

				<v-divider vertical class="mx-2" />
				<field-label :imodel="imodel" name="sql_is_processed_otcheti" class="py-3" />
				<quick-search class="ma-1" style="max-width: 200px;" field="sql_is_processed_otcheti" :op="FilterOps.EQUALS" input-type="btn-toggle" />
			</v-sheet>
		</quick-search-set>
		<Search @filter="filter = $event" />
		<list
			ref="list"
			v-if="imodel.loaded"
			:imodel="imodel"
			:headers="[
				'n_mg_jobs.id',
				'n_imot.imot_N',
				'n_imot.address',
				'n_mg_jobs.created_time',
				'n_mg_jobs.created_by',
				'n_mg_jobs.status',
				'n_mg_jobs.completed_by',
				'scheduled_datetime',
				'services_summary',
				'n_mg_jobs.processing_assignment_user_id',
				'sql_is_processed_montaji',
				'n_mg_jobs.montaji_processed_by',
				'sql_is_processed_otcheti',
				'n_mg_jobs.otcheti_processed_by',
			]"
			hidden-cols="n_mg_jobs.station_id,n_mg_jobs.imot_id"
			:filter="filter2"
			item-key="n_mg_jobs.id"
			hide-select
			hide-delete
			@edit="onEdit($event['n_mg_jobs.id'])"
		>
			<template #item.services_summary="{value}">
				<div v-text="value" style="white-space: pre-line" />
			</template>
			<template #right-actions="{item}">
				<imot-details-auto-session-link
					text link small
					target-blank
					:utility="selectedUtility"
					:imot="item['n_mg_jobs.imot_id']"
					:station="item['n_mg_jobs.station_id']"
				/>
			</template>
		</list>
		<edit-popup ref="editPopup"
			title="Заявка"
			:imodel="imodelEdit"
			:id="editId"
			:dialog-max-width="1500"
			:left-col-width="200"
			:right-col-width="500"
			:fields="editFields"
			:disabled-fields="disabledEditFields"
			:edit-button-options="{
				showDelete: authorization.hasRole(RolesEnum.JOB_REQUESTS_DELETE),
			}"
			hidden-fields="schedule_id,scheduled_time_start"
			:data-append="{
				services: servicesDataFiltered,
				schedule_id: editScheduleId,
				scheduled_time_start: editScheduleTime,
			}"
			@loaded="onEditLoaded"
			@reload="onReload"
		>
			<template #edit.before-fields="{row}">
				<data-loader
					v-if="selectedUtility && row['imot_id']"
					:watch="{utility: selectedUtility, imot: row['imot_id']}"
					:callback="() => imodelEdit.getImotInfo(selectedUtility, row['imot_id'])"
					:data.sync="imotInfo"
				>
					<template #default>
						<v-row v-if="imotInfo" no-gutters justify="center">
							<v-alert type="info" text dense>
								Имот {{imotInfo.imot_N}}, {{imotInfo.address}}
							</v-alert>
						</v-row>
					</template>
				</data-loader>
			</template>
			<template #edit.after-row.scheduled_duration_minutes="{row,loading,leftColStyle,rightColStyle}">
				<v-row no-gutters justify="center" v-if="!loading">
					<v-col :style="leftColStyle" class="py-1">
						Час по график
					</v-col>
					<v-col :style="rightColStyle" class="pa-1">
						<schedule-details
							:schedule-id="editScheduleId"
							:time-start="editScheduleTime?.substr(0, 5)"
							:duration="parseInt(row['scheduled_duration_minutes'])"
							class="mb-2"
						/>
						<schedule-select
							:schedule-id.sync="editScheduleId"
							:time-start.sync="editScheduleTime"
							:duration="parseInt(row['scheduled_duration_minutes'])"
							@change="onScheduleChange(row)"
							:record-id="editId"
							:imot-id="row['imot_id']"
							:dialog-title="imotInfo ? `Избор от график: Имот ${imotInfo.imot_N}, ${imotInfo.address}` : null"
						/>
					</v-col>
				</v-row>
			</template>
			<template #edit.after-fields="{row}">
				<v-divider class="mt-2" />
				<JobItemsList
					:job-id="editId==='new' ? null : editId"
					:imot="row['imot_id']"
					v-model="servicesData"
					:readonly="row['status']=='completed'"
				/>
			</template>
		</edit-popup>
	</div>
</template>
<script>
/* eslint-disable @typescript-eslint/camelcase */
import List from "@/ittijs/List.vue";
import EditPopup from "@/ittijs/components/EditPopup.vue";
import JobItemsList from "@/views/frontdesk/JobItemsList.vue";
import ScheduleSelect from "@/views/montaji/job_requests/ScheduleSelect.vue";
import ScheduleDetails from "@/views/montaji/job_requests/ScheduleDetails.vue";
import ITTIModel, {FilterOps} from "@/ittijs/ITTIModel";
import ModelEdit from "./Model";
import Search from "@/ittijs/Search.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import {cloneDeep} from "lodash";
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import QuickSearch from "@/ittijs/QuickSearch.vue";
import QuickSearchSet from "@/ittijs/QuickSearchSet.vue";
import ImotDetailsAutoSessionLink from "@/ittijs/components/ImotDetailsAutoSessionLink.vue";
import FieldLabel from "@/ittijs/components/FieldLabel.vue";
import {RolesEnum} from "@/ittijs/Authorization";
import DataLoader from "@/ittijs/components/DataLoader.vue";

const imodel = new ITTIModel('JobRequestsFull');
const imodelEdit = ITTIModelProvider.getModel(ModelEdit);

export default {
	mixins: [SelectedUtilityMixin],
	components: {
		DataLoader,
		FieldLabel,
		ImotDetailsAutoSessionLink,
		QuickSearchSet,
		QuickSearch,
		Search,
		ScheduleDetails,
		ScheduleSelect,
		JobItemsList,
		EditPopup,
		List,
	},
	inject: ['authorization'],
	props: [
		'station',
		'imot',
	],
	provide(){
		return {
			imodel: this.imodel,
		};
	},
	data(){
		return {
			imodel,
			imodelEdit,
			editId: null,
			servicesData: {},
			editScheduleId: null,
			editScheduleTime: null,
			filter: {},
			filterQuick: {},
			imotInfo: null,
		}
	},
	computed: {
		RolesEnum() {
			return RolesEnum
		},
		FilterOps() {
			return FilterOps
		},
		filter2: function(){
			if (!this.selectedUtility) return this.filter;
			const f2 = cloneDeep(this.filter);
			f2.groups = {
				quick: this.filterQuick,
				constrains: {
					rules: {
						utility_code: {
							field: 'n_mg_jobs.utility_code',
							op: 'eq',
							value: this.selectedUtility,
						}
					}
				}
			};
			return f2;
		},
		editFields(){
			if (this.editId === 'new') {
				return [
					'status',
					'scheduled_duration_minutes',
					'notes_operator',
				];
			}
			else {
				return [
					'created_time',
					'created_by',
					'status',
					'scheduled_duration_minutes',
					'payment_type',
					'notes_operator',
					'time_started',
					'time_completed',
					'completed_by',
					'notes_montajnik',
				];
			}
		},
		disabledEditFields(){
			return [
				'created_time',
				'created_by',
				'time_started',
				'time_completed',
				'completed_by',
				'notes_montajnik',
			];
		},
		servicesDataFiltered(){
			return {
				values_device : this.servicesData && this.servicesData.values_device && this.servicesData.values_device.map(item => {
					item = {...item};
					delete item['__device'];
					return item;
				}),
				values_imot   : this.servicesData && this.servicesData.values_imot,
			}
		},
	},
	methods: {
		onAdd(){
			this.editId = 'new';
			this.$refs.editPopup.open();
		},
		onEdit(id){
			this.editId = id;
			this.$refs.editPopup.open();
		},
		onEditLoaded(event){
			this.editScheduleId = event.row.schedule_id;
			this.editScheduleTime = event.row.scheduled_time_start;
		},
		onReload(){
			this.$refs.list.refresh();
		},
		onScheduleChange(row){
			if (this.editScheduleId && this.editScheduleTime) {
				if (row['status']=='' || row['status']=='requested') {
					row['status'] = 'scheduled';
				}
			}
			else {
				if (row['status']=='scheduled') {
					row['status'] = 'requested';
				}
			}

		},
	},
}
</script>