<template>
	<edit-input
		type="select"
		v-bind="$attrs"
		v-on="$listeners"
		:options="itemsFiltered"
		ref="select"
	>
		<template #item="data">
			<v-list-item v-bind="data.attrs" v-on="data.on">
				<v-list-item-icon class="mr-1">
					<v-icon v-if="data.item.is_radio==='1'" small color="green" title="Радио">mdi-wifi</v-icon>
					<v-icon v-else small color="grey" title="Радио">mdi-wifi-off</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					{{data.item.brand}} {{data.item.model}} ({{ data.item.device_type_label }})
				</v-list-item-content>
			</v-list-item>
		</template>
		<template #selection="data">
			<v-icon v-if="data.item.is_radio==='1'" small color="green" title="Радио">mdi-wifi</v-icon>
			<v-icon v-else small color="grey" title="Радио">mdi-wifi-off</v-icon>
			<div class="ml-2">{{data.item.brand}} {{data.item.model}} ({{ data.item.device_type_label }})</div>
		</template>
	</edit-input>
</template>

<script>
import {itemsForSelect} from '@/ittijs/utils';
import Model from './Model.js';
import EditInput from "@/ittijs/Inputs/EditInput";

const model = new Model;
const itemsP = model.getSelectItems(); // promise

export default {
	components: {EditInput},

	props: [
		'deviceType',
		'radio', // for sync to outside
	],

	data(){
		return {
			items: [],
		}
	},

	computed: {
		itemsFiltered(){
			return this.items.filter(i => {
				if (this.deviceType === 'ETALON') return true; // for etalon show all types
				return this.deviceType ? i.device_type_code===this.deviceType : true;
			});
		},
		selectedValue(){
			return this.$attrs.value || this.$attrs.data && this.$attrs.data[ this.$attrs.name ];
		},
	},

	async mounted() {
		this.items = itemsForSelect(await itemsP);
		this.itemsFiltered;
	},

	watch: {
		selectedValue(val){
			this.$emit('update:radio', this.items.find(i=>i.value==val)?.is_radio=='1');
		},
	},

}
</script>