<template>
	<data-loader v-if="scheduleId" :watch="scheduleId" :callback="()=>model.getRowById(scheduleId)">
		<template #default="{data}">
			<div v-if="data && timeStart && duration">
				<date-format :date="data?.date" />,
				<time-period-format :start="timeStart" :duration="duration" />
				<br>
				<user-info v-for="(userId, index) in data.users_ids" :key="userId" :id="parseInt(userId)">
					<template #default="{user}"><span v-if="index > 0">, </span>{{ user.user }}</template>
				</user-info>
			</div>
		</template>
	</data-loader>
</template>

<script>
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import Model from "@/views/montaji/schedule/Model";
import DataLoader from "@/ittijs/components/DataLoader.vue";
import DateFormat from "@/ittijs/components/DateFormat.vue";
import UserInfo from "@/ittijs/components/UserInfo.vue";
import TimePeriodFormat from "@/ittijs/components/TimePeriodFormat.vue";

const model = ITTIModelProvider.getModel(Model);

export default {
	components: {TimePeriodFormat, UserInfo, DateFormat, DataLoader},
	props: {
		scheduleId: {},
		timeStart: String,
		duration: Number,
	},
	data: () => ({
		model,
	}),
	computed: {
	},
}
</script>