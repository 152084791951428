/**
 * PHP: lib/App/DeviceTypeInfo.php
 */
export const enum DeviceType {
	RAZPREDELITEL     = 'RAZPREDELITEL',
	TOPLOMER          = 'TOPLOMER',
	VODOMER_HOT       = 'VODOMER_HOT',
	VODOMER_COLD      = 'VODOMER_COLD',
	STUDOMER          = 'STUDOMER',

	SHTRANGLIRA       = 'SHTRANGLIRA',
	ETALON            = 'ETALON', // real device
	ETALON_SLAVE      = 'ETALON_SLAVE', // value is copied from another device of type 'ETALON'
	RADIATOR_BEZ_URED = 'RADIATOR_BEZ_URED',

	PROGNOZENDIAL     = 'PROGNOZENDIAL',
	KOREKCIA          = 'KOREKCIA',
}

/**
 * PHP: lib/App/DeviceTypeInfo.php
 */
export const DeviceTypeLabels = new Map<DeviceType, string>([
	[DeviceType.RAZPREDELITEL,     'Разпределител'],
	[DeviceType.TOPLOMER,          'Топломер'],
	[DeviceType.VODOMER_HOT,       'Водомер за топла вода'],
	[DeviceType.VODOMER_COLD,      'Водомер за студена вода'],
	[DeviceType.STUDOMER,          'Студомер'],

	[DeviceType.SHTRANGLIRA,       'Щранг лира'],
	[DeviceType.ETALON,            'Еталонен уред'],
	[DeviceType.ETALON_SLAVE,      'Виртуален (по еталон)'],
	[DeviceType.RADIATOR_BEZ_URED, 'Радиатор без уред'],

	[DeviceType.PROGNOZENDIAL,     'Прогнозен дял сградна инсталация'],
	[DeviceType.KOREKCIA,          'Корекция'],
]);

/**
 * PHP: lib/App/DeviceTypeInfo.php
 */
export enum DeviceTypeGroupTypes {
	Physical,
	Heating,
	Radiator,
	Voda,
	Value2,
}

/**
 * PHP: lib/App/DeviceTypeInfo.php
 */
export const DeviceTypeGroups = new Map<DeviceTypeGroupTypes, Array<DeviceType>>([
	[DeviceTypeGroupTypes.Physical, [
		DeviceType.RAZPREDELITEL,
		DeviceType.ETALON,
		DeviceType.TOPLOMER,
		DeviceType.VODOMER_HOT,
		DeviceType.VODOMER_COLD,
		DeviceType.STUDOMER,
	]],
	[DeviceTypeGroupTypes.Heating, [
		DeviceType.RAZPREDELITEL,
		DeviceType.TOPLOMER,
		DeviceType.SHTRANGLIRA,
		DeviceType.ETALON,
		DeviceType.ETALON_SLAVE,
		DeviceType.RADIATOR_BEZ_URED,
	]],
	[DeviceTypeGroupTypes.Radiator, [
		DeviceType.RAZPREDELITEL,
		DeviceType.SHTRANGLIRA,
		DeviceType.ETALON,
		DeviceType.ETALON_SLAVE,
		DeviceType.RADIATOR_BEZ_URED,
	]],
	[DeviceTypeGroupTypes.Voda, [
		DeviceType.VODOMER_HOT,
		DeviceType.VODOMER_COLD,
	]],
	[DeviceTypeGroupTypes.Value2, [
		DeviceType.RAZPREDELITEL,
		DeviceType.ETALON,
	]],
]);

export function deviceInGroup(deviceType: DeviceType, group: DeviceTypeGroupTypes): boolean {
	const types = DeviceTypeGroups.get(group);
	return types && types.includes(deviceType) || false;
}

/**
 * PHP: lib/App/Services/Service.php
 */
export const enum MontajServiceTypes {
	imot          = 'imot',
	razpredelitel = 'razpredelitel',
	toplomer      = 'toplomer',
	vodomer       = 'vodomer',
}

/**
 * PHP: lib/App/Services/Service.php
 */
export const DeviceTypeToMontajServiceType = new Map<DeviceType, MontajServiceTypes>([
	[DeviceType.RAZPREDELITEL,     MontajServiceTypes.razpredelitel],
	[DeviceType.ETALON,            MontajServiceTypes.razpredelitel],
	[DeviceType.TOPLOMER,          MontajServiceTypes.toplomer],
	[DeviceType.STUDOMER,          MontajServiceTypes.toplomer],
	[DeviceType.VODOMER_HOT,       MontajServiceTypes.vodomer],
	[DeviceType.VODOMER_COLD,      MontajServiceTypes.vodomer],
]);


/**
 * PHP: lib/App/DeviceData.php
 */
export const enum OtchetType {
	REDOVEN    = '1',
	SAMOOTCHET = '2',
	SLUJEBEN   = '3',
	NEREDOVEN  = '4',
}

/**
 * PHP: lib/App/DeviceData.php
 */
export const enum SamootchetType {
	BELEJKA = '1',
	TELEFON = '2',
	WEB     = '3',
	EMAIL   = '4',
}

interface OtchetDef {
	value: OtchetType;
	text: string;
	letter: string;
	color: string;
}

interface SamootchetDef {
	value: SamootchetType;
	text: string;
	icon: string;
	color: string;
}

export const optionsOtchet: Array<OtchetDef> = [
	{
		value: OtchetType.REDOVEN,
		text: 'Редовен',
		letter: 'Р',
		color: 'green accent-2',
	},
	{
		value: OtchetType.SAMOOTCHET,
		text: 'Самоотчет',
		letter: 'С',
		color: '',
	},
	{
		value: OtchetType.SLUJEBEN,
		text: 'Служебен',
		letter: 'Сл',
		color: 'blue accent-2',
	},
	{
		value: OtchetType.NEREDOVEN,
		text: 'Нередовен',
		letter: 'Н',
		color: 'red accent-2',
	},
];

export const optionsSamootchet: Array<SamootchetDef> = [
	{
		value: SamootchetType.BELEJKA,
		text: 'Бележка',
		icon: 'mdi-note',
		color: 'cyan accent-2',
	},
	{
		value: SamootchetType.TELEFON,
		text: 'Телефон',
		icon: 'mdi-phone',
		color: 'green accent-2',
	},
	{
		value: SamootchetType.WEB,
		text: 'Web',
		icon: 'mdi-web',
		color: 'blue accent-2',
	},
	{
		value: SamootchetType.EMAIL,
		text: 'Имейл',
		icon: 'mdi-email',
		color: 'red accent-2',
	},
];

export const enum JobRequestPaymentType {
	cash = 'cash',
	pos = 'pos',
	bank = 'bank',
}

export const JobRequestPaymentTypeLabels = new Map<JobRequestPaymentType, string>([
	[JobRequestPaymentType.cash, 'В брой'],
	[JobRequestPaymentType.pos,  'POS'],
	[JobRequestPaymentType.bank, 'Банка'],
]);

/**
 * PHP: lib/App/Services/Service.php
 */
export const enum JobServiceIDs {
	Findings = 3,
	DogovorVoda = 23,
	Otchet = 37,
}

export const TalonPlombiDiamOptions = [
	'1/2',
	'3/4',
];

const enum VodomerType {
	Hot = 'hot',
	Cold = 'cold',
}

export const TalonPlombiDeviceTypeOptions = new Map<VodomerType, string>([
	[VodomerType.Hot, 'Топъл'],
	[VodomerType.Cold, 'Студен'],
]);

export const TalonPlombiDeviceTypeColorOptions = new Map<VodomerType, string>([
	[VodomerType.Hot, 'red white--text'],
	[VodomerType.Cold, 'light-blue white--text'],
]);

/**
 * PHP: lib/App/Tickets/Enum/Status.php
 */
export const enum TicketStatus {
	FOR_SCAN                   = 'for_scan',
	DOC_IN                     = 'doc_in',
	FOR_ASSIGN                 = 'for_assign',
	FOR_RESPONSE_BY_FRONT_DESK = 'for_response_by_frontdesk',
	FOR_RESPONSE_BY_TECH       = 'for_response_by_tech',
	FOR_RESPONSE_BY_ADM        = 'for_response_by_adm',
	RESPONSE                   = 'response',
	QUESTION_FOR_CUSTOMER      = 'question_for_customer',
	RESPONSE_FOR_CUSTOMER      = 'response_for_customer',
	NOTICE_ACCEPTED            = 'notice_accepted',
}

interface TicketStatusInfo {
	label: string;
	icon: string;
	color: string;
}

export const TicketStatusMap = new Map<TicketStatus, TicketStatusInfo>([
	[TicketStatus.FOR_SCAN,                   { label: 'За сканиране',     icon: 'mdi-scanner',                    color: 'blue'   }],
	[TicketStatus.DOC_IN,                     { label: 'Входиран док.',    icon: 'mdi-import',                     color: 'blue'   }],
	[TicketStatus.FOR_ASSIGN,                 { label: 'За насочване',     icon: 'mdi-call-split',                 color: 'orange' }],
	[TicketStatus.FOR_RESPONSE_BY_FRONT_DESK, { label: 'За кл. отдел',     icon: 'mdi-progress-question',          color: 'orange' }],
	[TicketStatus.FOR_RESPONSE_BY_TECH,       { label: 'За техн. отдел',   icon: 'mdi-progress-question',          color: 'orange' }],
	[TicketStatus.FOR_RESPONSE_BY_ADM,        { label: 'За адм. отдел',    icon: 'mdi-progress-question',          color: 'orange' }],
	[TicketStatus.RESPONSE,                   { label: 'Становище',        icon: 'mdi-comment-check-outline',      color: 'blue'  }],
	[TicketStatus.QUESTION_FOR_CUSTOMER,      { label: 'Въпр. към клиент', icon: 'mdi-chat-question-outline',      color: 'blue'   }],
	[TicketStatus.RESPONSE_FOR_CUSTOMER,      { label: 'Отг. към клиент',  icon: 'mdi-export-variant',             color: 'green'  }],
	[TicketStatus.NOTICE_ACCEPTED,            { label: 'За информация',    icon: 'mdi-folder-information-outline', color: 'green'  }],
]);

export const TicketDocumentTypes = [
	'жалба',
	'молба',
	'призовка',
	'решение',
	'протокол',
];

export const enum TicketRefNumType {
	NONE = 0,
	IN   = 1,
	OUT  = 2,
}

export const months = [
	'Януари',
	'Февруари',
	'Март',
	'Април',
	'Май',
	'Юни',
	'Юли',
	'Август',
	'Септември',
	'Октомври',
	'Ноември',
	'Декември',
];

export const monthsShort = [
	'Яну',
	'Фев',
	'Мар',
	'Апр',
	'Май',
	'Юни',
	'Юли',
	'Авг',
	'Сеп',
	'Окт',
	'Ное',
	'Дек',
];

export const weekdays = [
	'Неделя',
	'Понеделник',
	'Вторник',
	'Сряда',
	'Четвъртък',
	'Петък',
	'Събота',
];

export const weekdaysShort = [
	'Нед',
	'Пон',
	'Вто',
	'Сря',
	'Чет',
	'Пет',
	'Съб',
];

/**
 * Distance 144deg, 2 loops
 */
export const colorPalette5 = [
	'hsl(0   50% 50%)',
	'hsl(144 50% 40%)',
	'hsl(288 50% 50%)',
	'hsl(72  50% 40%)',
	'hsl(216 50% 50%)',
];

/**
 * Distance 80deg, 2 loops
 */
export const colorPalette9 = [
	'hsl(0   50% 50%)',
	'hsl(80  50% 40%)',
	'hsl(160 50% 40%)',
	'hsl(240 50% 60%)',
	'hsl(320 50% 50%)',
	'hsl(40  50% 50%)',
	'hsl(120 50% 50%)',
	'hsl(200 50% 50%)',
	'hsl(280 50% 50%)',
];

/**
 * Distance 60deg (except last one - 30deg), 2 loops
 */
export const colorPalette12 = [
	'hsl(0   50% 50%)',
	'hsl(60  50% 40%)',
	'hsl(120 50% 40%)',
	'hsl(180 50% 40%)',
	'hsl(240 50% 60%)',
	'hsl(300 50% 50%)',
	'hsl(30  50% 50%)',
	'hsl(90  50% 40%)',
	'hsl(150 50% 40%)',
	'hsl(210 50% 50%)',
	'hsl(270 50% 60%)',
	'hsl(330 50% 50%)',
];

/**
 * Distance 48deg, 2 loops
 */
export const colorPalette15 = [
	'hsl(0   50% 50%)',
	'hsl(48  50% 40%)',
	'hsl(96  50% 40%)',
	'hsl(144 50% 50%)',
	'hsl(192 50% 50%)',
	'hsl(240 50% 60%)',
	'hsl(288 50% 50%)',
	'hsl(336 50% 50%)',
	'hsl(24  50% 50%)',
	'hsl(72  50% 40%)',
	'hsl(120 50% 50%)',
	'hsl(168 50% 50%)',
	'hsl(216 50% 50%)',
	'hsl(264 50% 60%)',
	'hsl(312 50% 50%)',
];
