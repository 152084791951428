<template>
	<div class="d-flex">

		<slot name="left"></slot>

		<v-spacer/>

		<slot name="right-inside"></slot>

		<v-btn
			@click="$emit('delete')"
			ref="btnDelete"
			color="red"
			text
			:dark="!disabled"
			:disabled="disabled"
			:small="dense"
			v-if="showDelete"
			:icon="breakpoint"
			:class="marginClassLeft"
			:x-large="breakpoint"
		>
			<v-icon :left="!breakpoint">mdi-delete</v-icon>
			<template v-if="!breakpoint">{{labelDelete}}</template>
		</v-btn>
		<v-tooltip top v-if="breakpoint" :activator="$refs.btnDelete">{{labelDelete}}</v-tooltip>

		<v-btn
			@click="$emit('close')"
			ref="btnClose"
			color="grey"
			text
			:dark="!disabled"
			:disabled="disabled"
			:small="dense"
			v-if="showClose"
			:icon="breakpoint"
			:class="marginClassLeft"
			:x-large="breakpoint"
		>
			<v-icon :left="!breakpoint">mdi-window-close</v-icon>
			<template v-if="!breakpoint">{{labelClose}}</template>
		</v-btn>
		<v-tooltip top v-if="breakpoint" :activator="$refs.btnClose">{{labelClose}}</v-tooltip>

		<v-btn
			@click="$emit('save')"
			ref="btnSave"
			color="primary"
			:dark="!disabled"
			:disabled="disabled"
			:small="dense"
			v-if="showSave"
			:icon="breakpoint"
			:class="marginClassLeft"
			:x-large="breakpoint"
		>
			<v-icon :left="!breakpoint">mdi-content-save</v-icon>
			<template v-if="!breakpoint">{{labelSave}}</template>
		</v-btn>
		<v-tooltip top v-if="breakpoint" :activator="$refs.btnSave">{{labelSave}}</v-tooltip>

		<slot name="right-outside"></slot>

	</div>
</template>

<script>
export default {
	components: {
	},
	props: {
		disabled: Boolean,
		dense: Boolean,
		showDelete: {default: false},
		showSave: {default: true},
		showClose: {default: true},
		labelSave: {default:'Запис'},
		labelDelete: {default:'Изтрий'},
		labelClose: {default:'Затвори'},
	},
	computed: {
		marginClassRight(){
			return this.breakpoint ? '' : 'mr-2';
		},
		marginClassLeft(){
			return this.breakpoint ? '' : 'ml-2';
		},
		breakpoint(){
			return this.$vuetify.breakpoint.xs;
		},
	},
}
</script>