
import IModelMixin from './IModelMixin';

import SearchInput from "@/ittijs/Inputs/SearchInput.vue";
import SearchInput2 from "@/ittijs/Inputs/SearchInput2.vue";

import _ from "lodash";


export default {
	mixins: [IModelMixin],

	components: {
		SearchInput,
		SearchInput2,
	},

	data: function () {
		return {
			open: false,
			searchText: "",
			filter: this.calcFilter(),
		};
	},

	props: {
		defaults: Object,
	},

	mounted() {
		//this.reset();
		this.search();
	},

	methods: {
		calcFilter() {
			return _.merge({
				glue  : "AND",
				rules : [],
			}, this.defaults);
		},

		reset() {
			this._applyDefaults(this.filter, this.defaults);

			//Walk this.filter and apply values from this.defaults, if no value exist apply null

			this.search();
		},

		search() {
			this.$emit("filter", this.buildFilter());
		},

		buildFilter() {
			return this._extract(this.filter);
			//return JSON.parse(JSON.stringify(this.filter));
		},

		_extract(filter) {
			const rules = {};
			for (const r in filter.rules) {
				const inRule = filter.rules[r];
				if (!inRule.op || !inRule.field) continue;
				const rule = {
					field: inRule.field,
					op: inRule.op,
					value: inRule.value,
				};
				if (inRule.not) rule.not = true;
				rules[r] = rule;
			}

			const groups = {};
			for (const g in filter.groups) {
				const grp = this._extract(filter.groups[g]);
				if (!_.isEmpty(grp)) {
					groups[g] = grp;
				}
			}

			if (_.isEmpty(groups) && _.isEmpty(rules)) return {};

			const result = {
				glue: filter.glue,
			};
			if (!_.isEmpty(rules)) result.rules = rules;
			if (!_.isEmpty(groups)) result.groups = groups;

			return result;
		},

		_applyDefaults(filter, defaults) {
			if (filter.rules)
				for (const r in filter.rules) {
					filter.rules[r].value =
						defaults && defaults.rules && defaults.rules[r]
							? defaults.rules[r].value
							: null;
				}

			if (filter.groups)
				for (const g in filter.groups) {
					this._applyDefaults(
						filter.groups[g],
						defaults && defaults.groups ? defaults.groups[g] : {}
					);
				}
		},
	},

};