<script>
import FilterValue from "@/ittijs/Filter/Value.vue";
import IModelMixin from "@/ittijs/IModelMixin";

export default {
	mixins: [IModelMixin],
	components : {FilterValue},
	inject: [
		'quickSearchSet',
	],
	props: {
		field: String,
		op: String,
		label: String,
		value: {},
	},
	data(){
		return {
			ruleIndex: null,
			rule: null,
		}
	},
	computed: {
		labelCalc(){
			return this.label || this.imodel.fields[ this.field ]?.label
		},
	},
	watch: {
		field(val) {
			if (this.rule) this.$set(this.rule, 'field', val);
		},
		op(val) {
			if (this.rule) this.$set(this.rule, 'op', val);
		},
	},
	beforeMount() {
		if (this.quickSearchSet) {
			this.ruleIndex = this.quickSearchSet.initRule({
				field : this.field,
				op    : this.op,
				value : this.value,
			});
			this.rule = this.quickSearchSet.getRule(this.ruleIndex);
		}
	},
	beforeDestroy() {
		if (this.quickSearchSet && this.ruleIndex !== null) {
			this.quickSearchSet.endRule(this.ruleIndex);
			this.ruleIndex = null;
			this.rule = null;
		}
	},
	render(createElement) {
		if (!this.rule) return null;
		return this.$scopedSlots.default
			? this.$scopedSlots.default({rule: this.rule})
			: createElement(FilterValue, {
				attrs: {
					...this.$attrs,
					rule: this.rule,
					label: this.labelCalc,
				},
				domProps: {
					value: this.rule.value
				},
				on: {
					input: e => {
						this.rule.value = e;
					}
				}
			})
	},
}
</script>